import { useCallback } from 'react'

import {
  getLocation as getSpotCurrentPosition,
  openSetting as openSpotSetting,
  navigateLocation as spotNavigateLocation,
} from '@chargespot/spjs'

import { useConfirm } from '@/utils/confirm'
import { DEFAULT_POSITION } from '@/config/constants'
import { Country } from '@/config/country'

export type Position = {
  latitude: number
  longitude: number
}

// Promise style getCurrentPosition
function getNavigatorCurrentPosition(): Promise<Position> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        resolve({ latitude, longitude })
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export function useGetCurrentPosition() {
  const confirm = useConfirm()

  return useCallback(async () => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      return await getNavigatorCurrentPosition().catch(
        () => DEFAULT_POSITION[Country.JP]
      )
    }

    return await getSpotCurrentPosition().catch(async (e) => {
      if (e.errCode === -2) {
        return await confirm({
          content: 'Allow Position Permission in SpotApp',
        })
          .then(async () => {
            await openSpotSetting()
            return await getSpotCurrentPosition()
          })
          .catch(() => {
            return DEFAULT_POSITION[Country.JP]
          })
      }
      return Promise.reject(e)
    })
  }, [confirm])
}

export interface NavigateOptions {
  latitude: number
  longitude: number
  name?: string
}

export function useNavigateLocation() {
  return useCallback(async (options: NavigateOptions) => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      const googleUrl = new URL('https://maps.google.com/')
      googleUrl.searchParams.set(
        'q',
        `${options.latitude},${options.longitude}`
      )
      return window.open(googleUrl.toString())
    }

    return await spotNavigateLocation(options)
  }, [])
}
