import { useT } from '@/utils/language'

import Modal from '@/components/Modal'

import styles from './IndexAreaModal.module.css'

type IndexAreaModalProps = {
  visible: boolean
  onSelect: () => void
  onClose: () => void
}

function IndexAreaModal(props: IndexAreaModalProps) {
  const T = useT()
  const { visible, onSelect, onClose } = props
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.hint}>{T('rentalPositionCheck')}</div>

        <div className={styles.buttonGroup}>
          <button className={styles.button} onClick={onSelect}>
            {T('rentalPositionNew')}
          </button>
          <button className={styles.button} onClick={onSelect} disabled>
            {T('rentalPositionOld')}
            <div className={styles.buttonDisableMask}>
              {T('unavailableNow')}
            </div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default IndexAreaModal
