import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useT } from '@/utils/language'
import { Kasa } from '@/utils/kasa'
import { useIkasaId } from '@/utils/setting'
import { useRequest } from '@/utils/request'
import { useUser } from '@/utils/user'
import { PlanType } from '@/utils/plan'

import Page from '@/components/Page'
import Menu, { MenuItem, MenuItemProps } from '@/components/Menu'
import Icon from '@/components/Icon'

import ProfileKasaList from './ProfileKasaList'

import styles from './ProfilePage.module.css'

type MenuItemWithPath = MenuItemProps & { path?: string }

function ProfilePage() {
  const T = useT()
  const navigate = useNavigate()
  const ikasaId = useIkasaId()
  const user = useUser()
  const request = useRequest()

  const [rentingKasaList, setRentingKasaList] = useState<Kasa[]>([])
  const getRentingKasaList = useCallback(async () => {
    const kasaList = await request<Kasa[]>(
      `/service/v1/return/confirm/${ikasaId}`
    )
    setRentingKasaList(kasaList)
  }, [request, ikasaId])
  useEffect(() => {
    getRentingKasaList()
  }, [getRentingKasaList])

  const topMenuList = useMemo<MenuItemWithPath[]>(() => {
    return [
      {
        icon: 'monetizationOn',
        text: T('usageDetail'),
        hint: T('usageDetailHint'),
        path: '/profile/amount',
      },
      {
        icon: 'cached',
        text: T('changePlan'),
        hint: T('changePlanHint'),
        path: '/profile/plan',
      },
      {
        icon: 'trendingUp',
        text: T('manualDetail'),
        hint: T('manualDetailHint'),
        path: '/how-to',
      },
      {
        icon: 'person',
        text: T('accountInformation'),
        hint: T('accountInformationHint'),
        path: '/profile/account',
      },
      {
        icon: 'home',
        text: T('kasaLostApply'),
        hint: T('kasaLostApplyHint'),
        path: '/kasa-lost',
      },
      {
        icon: 'style',
        text: T('useCoupon'),
        hint: T('useCouponHint'),
        path: '/coupon-redeem',
      },
    ]
  }, [T])

  const bottomMenuList = useMemo<MenuItemWithPath[]>(() => {
    return [
      {
        icon: 'chat',
        text: T('help'),
        path: '/faq',
      },
      {
        icon: 'filterNone',
        text: T('userAgreement'),
        path: 'https://www.i-kasa.com/terms',
      },
      {
        icon: 'description',
        text: T('privacyPolicy'),
        path: 'https://www.i-kasa.com/privacy',
      },
    ]
  }, [T])

  const onMenuItemClick = useCallback(
    (item: MenuItemWithPath) => {
      if (!item.path) return

      if (item.path.startsWith('/')) {
        navigate(item.path)
        return
      }

      window.open(item.path)
    },
    [navigate]
  )

  if (!user) return null

  return (
    <Page className={styles.page}>
      {rentingKasaList.length === 0 && (
        <div className={styles.noRenting}>{T('noRentingKasa')}</div>
      )}

      {rentingKasaList.length > 0 && (
        <ProfileKasaList
          className={styles.kasaList}
          kasaList={rentingKasaList}
        />
      )}

      {user.planType !== PlanType.Subscription && (
        <button
          className={styles.trySubscriptionPlan}
          onClick={() => {
            const query = new URLSearchParams({
              next: PlanType.Subscription,
            }).toString()
            navigate(`/profile/plan/change?${query}`)
          }}
        >
          {T('trySubscriptionPlan')}
          <Icon
            className={styles.trySubscriptionPlanIcon}
            name="chevronRight"
          />
        </button>
      )}

      <Menu className={styles.topMenu}>
        {topMenuList.map((item) => (
          <MenuItem
            key={item.text}
            {...item}
            border
            showArrow
            onClick={() => {
              onMenuItemClick(item)
            }}
          />
        ))}
      </Menu>

      <Menu className={styles.bottomMenu}>
        {bottomMenuList.map((item) => (
          <MenuItem
            key={item.text}
            {...item}
            showArrow
            onClick={() => {
              onMenuItemClick(item)
            }}
          />
        ))}
      </Menu>
    </Page>
  )
}

export default ProfilePage
