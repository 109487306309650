import { useEffect, useRef } from 'react'

function scrollToIndex(container: HTMLDivElement, index: number) {
  container.scroll?.({
    left: container.clientWidth * index,
    behavior: 'smooth',
  })
}

const scrollToNextIndex = function (container: HTMLDivElement) {
  const progress =
    container.scrollLeft / (container.scrollWidth - container.clientWidth)
  const currentIndex = Math.round(container.scrollLeft / container.clientWidth)
  const nextIndex = progress === 1 ? 0 : currentIndex + 1
  scrollToIndex(container, nextIndex)
}

type SwiperOptions = {
  interval: number
}

export function useSwiper(options: SwiperOptions) {
  const elementRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<number>()
  useEffect(() => {
    const container = elementRef.current
    if (!container) return
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
      scrollToIndex(container, 0)
    }
    intervalRef.current = window.setInterval(() => {
      scrollToNextIndex(container)
    }, options.interval)
  }, [options.interval])

  return { elementRef }
}
