import { useCallback } from 'react'

import jaJPJSON from '@/languages/ja-JP.json'

// using japan language key as the type guide
type LanguageJSON = typeof jaJPJSON
type LanguageString = keyof LanguageJSON

export enum Language {
  jaJP = 'ja-JP',
}

const languageJsonMap: Record<Language, typeof jaJPJSON> = {
  [Language.jaJP]: jaJPJSON,
}

function T(languageString: LanguageString, language: Language) {
  return languageJsonMap[language][languageString]
}

export function useT() {
  // TODO: use useContext for i18n
  const currentLanguage = Language.jaJP

  return useCallback(
    (languageString: LanguageString) => T(languageString, currentLanguage),
    [currentLanguage]
  )
}
