import { useContext, useCallback, createContext, useMemo } from 'react'

type LoadingUpdater = (list: symbol[]) => symbol[]

export type LoadingContextType = {
  loading: symbol[]
  setLoading: (updator: LoadingUpdater) => void
}

export const LoadingContext = createContext<LoadingContextType>({
  loading: [],
  setLoading: () => {},
})

export const LoadingProvider = LoadingContext.Provider

export function useLoading() {
  const { setLoading } = useContext(LoadingContext)

  const showLoading = useCallback(() => {
    const task = Symbol('loading')
    setLoading((list) => [...list, task])
    return task
  }, [setLoading])

  const hideLoading = useCallback(
    (task: symbol) => {
      setLoading((list) => list.filter((l) => l !== task))
    },
    [setLoading]
  )

  return useMemo<[() => symbol, (task: symbol) => void]>(
    () => [showLoading, hideLoading],
    [showLoading, hideLoading]
  )
}
