import cx from 'classnames'

import Icon, { IconName } from '@/components/Icon'

import styles from './Menu.module.css'

type MenuProps = {
  className?: string
  children: React.ReactNode
}

function Menu(props: MenuProps) {
  const { className, children } = props
  return <div className={cx(styles.menu, className)}>{children}</div>
}

export type MenuItemProps = {
  className?: string
  icon?: IconName
  text: string
  hint?: React.ReactNode
  border?: boolean
  size?: 'normal' | 'large'
  onClick?: () => void
  showArrow?: boolean
  right?: React.ReactNode
}

export function MenuItem(props: MenuItemProps) {
  const {
    className,
    icon,
    text,
    hint,
    size = 'normal',
    border = false,
    showArrow = false,
    right,
    onClick,
  } = props
  return (
    <div
      className={cx(styles.item, className, {
        [styles.borderItem]: border,
        [styles.largeItem]: size === 'large',
      })}
      onClick={onClick}
    >
      {icon && <Icon className={styles.itemIcon} name={icon} />}
      <div className={styles.content}>
        <div className={styles.text}>{text}</div>
        {hint && <div className={styles.hint}>{hint}</div>}
      </div>
      {showArrow && <Icon className={styles.itemArrow} name="chevronRight" />}
      {right}
    </div>
  )
}

export default Menu
