import {
  closeBluetoothAdapter,
  openBluetoothAdapter,
  requestBindPayment,
} from '@chargespot/spjs'
import cx from 'classnames'
import { format as formatDate, parseISO } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { DEFAULT_POSITION } from '@/config/constants'
import { Country } from '@/config/country'
import { AutoAction } from '@/utils/action'
import { Banner } from '@/utils/banner'
import { useConfirm } from '@/utils/confirm'
import { useScan } from '@/utils/device'
import { ResponseError } from '@/utils/error'
import {
  parseStandUrl,
  ShareRequestConfirmContent,
  ShareRequestPayload,
} from '@/utils/kasa'
import { useT } from '@/utils/language'
import { Position, useGetCurrentPosition } from '@/utils/location'
import { useGetSetting, usePermit } from '@/utils/permission'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'
import { Spot } from '@/utils/spot'
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/utils/storage'
import { useToast } from '@/utils/toast'
import { UIAction, useHandleUIAction } from '@/utils/uiAction'
import { useKasaCount } from '@/utils/user'

import Button from '@/components/Button'
import KasaMap from '@/components/KasaMap'
import Page from '@/components/Page'

import IndexAreaModal from './IndexAreaModal'
import IndexBanner from './IndexBanner'
import IndexKasaCount from './IndexKasaCount'
import IndexSideButton from './IndexSideButton'
import IndexSpotCard from './IndexSpotCard'

import styles from './IndexPage.module.css'

function IndexPage() {
  const T = useT()
  const request = useRequest()
  const navigate = useNavigate()
  const toast = useToast()
  const [mapCurrentPosition, setMapCurrentPosition] = useState<Position>()
  const [position, setPosition] = useState<Position>()
  const [zoom, setZoom] = useState(16)
  const onMapChange = useCallback((position: Position, zoom: number) => {
    setPosition(position)
    setZoom(zoom)
  }, [])

  // position
  const getCurrentPosition = useGetCurrentPosition()
  const resetPosition = useCallback(async () => {
    const currentPosition = await getCurrentPosition().catch(() => {
      return DEFAULT_POSITION[Country.JP]
    })
    setMapCurrentPosition(currentPosition)
    setPosition(currentPosition)
    setZoom(16)
  }, [getCurrentPosition])
  useEffect(() => {
    resetPosition()
  }, [resetPosition])

  // spot
  const [mapSpots, setMapSpots] = useState<Spot[]>([])
  const getMapSpots = useCallback(async () => {
    const res = await request<{ spots: Spot[] }>('/db/v1/spot', {
      data: {
        // TODO: maybe type safe
        include: ['scd', 'position', 'is_maintenance', 'is_hidden'].join(','),
      },
    })
    setMapSpots(res.spots.filter((p) => p.position && !p.isHidden))
  }, [request])
  useEffect(() => {
    getMapSpots()
  }, [getMapSpots])

  // selecting spot
  const [selectingSpot, setSelectingSpot] = useState<Spot | null>(null)
  const onSelectSpot = useCallback(
    async (selectingSpot: Spot) => {
      const spot = await request<Spot>(`/db/v1/spot/scd/${selectingSpot.scd}`)
      setSelectingSpot((currentSpot) => {
        if (currentSpot?.scd === selectingSpot.scd) {
          return null
        }
        return spot
      })
    },
    [request]
  )

  // banner
  const [bannerList, setBannerList] = useState<Banner[]>([])
  const getBanner = useCallback(async () => {
    const res = await request<{ banners: Banner[] }>(
      '/service/v1/mypage/banners/map'
    )
    setBannerList(res.banners)
  }, [request])
  useEffect(() => {
    getBanner()
  }, [getBanner])

  // kasa count
  const [count, fetchKasaCount] = useKasaCount()

  // rental and return
  const ikasaId = useIkasaId()
  const scan = useScan()
  const confirm = useConfirm()

  const confirmShareRequest = useCallback(async () => {
    const { rental: lastRental, return: lastReturn } =
      await request<ShareRequestPayload>(
        `/service/v2/share/request/ikasa_id/${ikasaId}`
      )

    let confirmContents: ShareRequestConfirmContent[] = []

    if (lastRental) {
      confirmContents.push({
        action: 'rental',
        time: lastRental.processedAt,
        spotName: lastRental.spotName,
        ksid: lastRental.ksid,
        processId: lastRental.processId,
      })
    }

    if (lastReturn) {
      confirmContents.push({
        action: 'return',
        time: lastReturn.processedAt,
        spotName: lastReturn.spotName,
        ksid: lastReturn.ksid,
        processId: lastReturn.processId,
      })
    }

    for (let index = 0; index < confirmContents.length; index++) {
      const { action, time, spotName, ksid, processId } = confirmContents[index]
      await confirm({
        title:
          action === 'rental'
            ? T('lastRentalSuccessCheck')
            : T('lastReturnSuccessCheck'),
        content: (
          <div className={styles.lastConfirmContainer}>
            <div className={styles.lastConfirmGroup}>
              <div className={styles.lastConfirmContainerTitle}>
                {T('time')}
              </div>
              <div className={styles.lastConfirmContainerText}>
                {formatDate(parseISO(time), T('rentedAtFormat'))}
              </div>
            </div>
            <div className={styles.lastConfirmGroup}>
              <div className={styles.lastConfirmContainerTitle}>
                {T('spot')}
              </div>
              <div className={styles.lastConfirmContainerText}>{spotName}</div>
            </div>
          </div>
        ),
        cancel: T('failed'),
        confirm: T('succeed'),
      }).then(
        async () => {
          await request(`/service/v2/share/request/${action}`, {
            method: 'POST',
            data: { ikasaId, ksid, processId },
          })
          await fetchKasaCount()
        },
        () =>
          request(`/service/v2/share/request/process_id/${processId}`, {
            method: 'DELETE',
          })
      )
    }
  }, [ikasaId, confirm, T, request, fetchKasaCount])

  const getSetting = useGetSetting()
  const permit = usePermit()
  const [areaModalAction, setAreaModalAction] = useState<AutoAction | null>(
    null
  )
  const onAction = useCallback(
    async (autoAction: AutoAction) => {
      const { action } = autoAction

      //  bluetooth permission
      const setting = await getSetting()
      if (!setting.permissionSetting['scope.bluetooth']) {
        await permit('scope.bluetooth').catch(() =>
          // 1. ShareSPOT has bluetooth permission, but ikasa has not bluetooth permission
          confirm({
            title: T('permissionTitle'),
            content: T('permissionBluetoothMiniAppContent'),
            cancel: T('close'),
            confirm: T('completeSetting'),
          }).then(() => {
            window.location.reload()
            return Promise.reject('reload')
          })
        )
      }
      // 2. ShareSPOT has not bluetooth permission
      // 3. ShareSPOT has bluetooth permission, but not enabled
      await openBluetoothAdapter()
        .then(() => closeBluetoothAdapter())
        .catch(() =>
          confirm({
            title: T('permissionTitle'),
            content: T('permissionBluetoothPermissionContent'),
            cancel: T('close'),
            confirm: T('completeSetting'),
          }).then(() => {
            window.location.reload()
            return Promise.reject('reload')
          })
        )

      let standId = autoAction.standId
      // scan and camera permission
      if (!standId) {
        const scanResult = await scan().catch(async (e) => {
          if (e.errCode === -2) {
            await confirm({
              title: T('permissionTitle'),
              content: T('permissionCameraContent'),
              cancel: T('close'),
              confirm: T('completeSetting'),
            }).then(() => {
              window.location.reload()
            })
          }
          return Promise.reject(e)
        })

        standId = parseStandUrl(scanResult) || undefined

        if (!scanResult || !standId) {
          await confirm({
            title: null,
            content: T('scanUrlErrorHint'),
            confirm: T('close'),
            cancel: null,
          })
          return
        }
      }

      await request(`/service/v2/${action}/confirm`, {
        method: 'POST',
        showToast: false,
        data: { ikasaId, standId },
      }).catch((e: ResponseError) => {
        const error = e.errors?.[0]
        if (!error) return Promise.reject(e)

        if (
          (error.resource === 'Stand' && error.code === 'not_found') ||
          (error.resource === 'Settlement' && error.code === 'not_finished') ||
          (error.resource === 'Rental' && error.code === 'limit_over')
        ) {
          const query = new URLSearchParams(error)
          navigate(`/error?${query.toString()}`)
        }

        if (
          error.resource === 'SpotAppPayment' &&
          error.code === 'spot_payment_not_bind'
        ) {
          return requestBindPayment().catch((e) => {
            // Cancel with no toast
            if (e.errCode !== -1) toast(e.errMsg)
            return Promise.reject(e)
          })
        }

        return Promise.reject(e)
      })

      const params = new URLSearchParams({ action })
      navigate(`/unlock/${standId}?${params.toString()}`)
    },
    [T, getSetting, permit, request, ikasaId, scan, confirm, navigate, toast]
  )

  // ui action
  const hanldeUIAction = useHandleUIAction()
  useEffect(() => {
    request<{ action: UIAction | null }>('/service/v1/mypage/actions', {
      data: { ikasaId, where: 'home', when: 'opened' },
    }).then((res) => {
      if (res.action) hanldeUIAction(res.action)
    })
  }, [ikasaId, request, hanldeUIAction])

  // share request
  const location = useLocation()
  useEffect(() => {
    if (getStorageItem<true>('confirmMapShareRequest', 'session')) return
    confirmShareRequest()
    setStorageItem('confirmMapShareRequest', true, 'session')
  }, [confirmShareRequest, location])

  // ScanScene
  useEffect(() => {
    const autoAction = getStorageItem<AutoAction>('autoAction', 'session')
    if (!autoAction) return
    removeStorageItem('autoAction', 'session')

    setAreaModalAction(autoAction)
    confirmShareRequest()
  }, [confirm, confirmShareRequest])

  if (!position) return null

  return (
    <Page title={T('home')}>
      <KasaMap
        className={styles.map}
        position={position}
        currentPosition={mapCurrentPosition}
        zoom={zoom}
        onChange={onMapChange}
        spots={mapSpots}
        selectingSpot={selectingSpot}
        onSpotClick={onSelectSpot}
      />

      {/* top */}
      <div className={styles.topArea}>
        {bannerList.length > 0 && (
          <IndexBanner className={styles.banner} bannerList={bannerList} />
        )}
        {count !== null && <IndexKasaCount count={count} />}
      </div>

      {/* bottom left */}
      {selectingSpot && (
        <IndexSpotCard
          className={styles.spotCard}
          spot={selectingSpot}
          onClose={() => {
            setSelectingSpot(null)
          }}
        />
      )}
      <Button
        className={cx(styles.bottomButton, styles.rentalButton)}
        preset="primary"
        icon="beachAccess"
        text={T('rental')}
        onClick={() => {
          setAreaModalAction({ action: 'rental' })
          confirmShareRequest()
        }}
      />
      <Button
        className={cx(styles.bottomButton, styles.returnButton)}
        preset="primaryDarkBlue"
        icon="beachAccess"
        text={T('return')}
        onClick={() => {
          setAreaModalAction({ action: 'return' })
          confirmShareRequest()
        }}
      />

      {/* bottom right */}
      <IndexSideButton
        className={cx(styles.sideButton, styles.helpButton)}
        icon="chat"
        text={T('help')}
        onClick={() => {
          navigate('/faq')
        }}
      />
      <IndexSideButton
        className={cx(styles.sideButton, styles.locationButton)}
        icon="locationSearching"
        text={T('currentLocation')}
        onClick={() => {
          resetPosition()
        }}
      />

      <IndexAreaModal
        visible={areaModalAction !== null}
        onSelect={() => {
          if (!areaModalAction) return
          onAction(areaModalAction)
          setAreaModalAction(null)
        }}
        onClose={() => {
          setAreaModalAction(null)
        }}
      />
    </Page>
  )
}

export default IndexPage
