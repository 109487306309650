const KEY_PREFIX = 'IKASASPOT::'

type StorageValue<T> = {
  data: T
}

type StorageType = 'local' | 'session'

function getStorage(type: StorageType = 'local') {
  if (type === 'session') {
    return window.sessionStorage
  }
  return window.localStorage
}

export function setStorageItem<T>(
  key: string,
  data: T,
  storageType: StorageType = 'local'
) {
  const storage = getStorage(storageType)

  storage.setItem(`${KEY_PREFIX}${key}`, JSON.stringify({ data }))
}

export function getStorageItem<T>(
  key: string,
  storageType: StorageType = 'local'
) {
  const storage = getStorage(storageType)

  const stringValue = storage.getItem(`${KEY_PREFIX}${key}`)
  if (!stringValue) return null
  const storageValue = JSON.parse(stringValue) as StorageValue<T>
  return storageValue.data
}

export function removeStorageItem(
  key: string,
  storageType: StorageType = 'local'
) {
  const storage = getStorage(storageType)

  storage.removeItem(`${KEY_PREFIX}${key}`)
}
