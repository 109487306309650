import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'
import { useRequest } from '@/utils/request'
import { UserAmount } from '@/utils/user'
import { useIkasaId } from '@/utils/setting'

import Page from '@/components/Page'
import Icon from '@/components/Icon'

import styles from './ProfileAmountPage.module.css'
import Menu, { MenuItem } from '@/components/Menu'

type AmountItemProps = {
  className?: string
  title: string
  body: string
  footer?: string
  footerStyle?: React.CSSProperties
}

function AmountItem(props: AmountItemProps) {
  const { className, title, body, footer, footerStyle } = props
  return (
    <div className={cx(styles.amountItem, className)}>
      <div className={styles.amountTitle}>{title}</div>
      <div className={styles.amountContainer}>
        <div className={styles.amountBody}>{body}</div>
        {footer && (
          <div className={styles.amountFooter} style={footerStyle}>
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

function ProfileAmountPage() {
  const T = useT()
  const navigate = useNavigate()

  const request = useRequest()
  const ikasaId = useIkasaId()
  const [userAmount, setUserAmount] = useState<UserAmount>()
  useEffect(() => {
    request<UserAmount>(`/service/v1/mypage/amount/${ikasaId}`).then((res) => {
      setUserAmount(res)
    })
  }, [request, ikasaId])

  if (!userAmount) return null

  return (
    <Page className={styles.page} title={T('usageDetail')}>
      <div className={styles.amountList}>
        <AmountItem
          title={T('currentMonthAmount')}
          body={`${userAmount.settlement.nextMonthAmount}${T('jpy')}`}
          footer={userAmount.ui.planName}
          footerStyle={{ color: userAmount.ui.planColor }}
        />
        <AmountItem
          title={T('settlementDate')}
          body={userAmount.settlement.settlementDate}
        />
      </div>

      {userAmount.settlement.planType !== PlanType.Subscription && (
        <button
          className={styles.trySubscriptionPlan}
          onClick={() => {
            const query = new URLSearchParams({
              next: PlanType.Subscription,
            }).toString()
            navigate(`/profile/plan/change?${query}`)
          }}
        >
          <div className={styles.trySubscriptionText}>
            <div className={styles.trySubscriptionTextTitle}>
              {T('trySubscriptionPlanMonth')}
            </div>
            <div className={styles.trySubscriptionTextHint}>
              {T('trySubscriptionPlanMonthHint')}
            </div>
          </div>
          <Icon
            className={styles.trySubscriptionPlanIcon}
            name="chevronRight"
          />
        </button>
      )}

      <Menu className={styles.menu}>
        <MenuItem
          icon="history"
          text={T('usageHistory')}
          hint={T('usageHistoryHint')}
          border
          showArrow
          onClick={() => {
            navigate('/profile/history')
          }}
        />
      </Menu>
    </Page>
  )
}

export default ProfileAmountPage
