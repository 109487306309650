import { Country } from '@/config/country'
import { Position } from '@/utils/location'

export const DEFAULT_POSITION: Record<Country, Position> = {
  [Country.HK]: { latitude: 22.308473, longitude: 114.183998 },
  [Country.JP]: { latitude: 35.681236, longitude: 139.767125 },
  [Country.MO]: { latitude: 22.195537, longitude: 113.545113 },
  [Country.MY]: { latitude: 3.140853, longitude: 101.693207 },
  [Country.TH]: { latitude: 13.744951, longitude: 100.533661 },
  [Country.TW]: { latitude: 25.078174, longitude: 121.575193 },
  [Country.CN]: { latitude: 23.097781, longitude: 113.368974 }, // guangzhou
  [Country.US]: { latitude: 40.776676, longitude: -73.971321 }, // new york
}

export const JP_POSITION = DEFAULT_POSITION[Country.JP]
