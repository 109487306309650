import Modal from '@/components/Modal'
import { useT } from '@/utils/language'

import LoadingIcon from './LoadingIcon'
import styles from './LoadingModal.module.css'

function LoadingModal() {
  const T = useT()

  return (
    <Modal mask={false} maskClosable={false}>
      <div className={styles.loading}>
        <LoadingIcon className={styles.loadingIcon} />
        {T('loading')}
      </div>
    </Modal>
  )
}

export default LoadingModal
