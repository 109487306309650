import cx from 'classnames'

import { useT } from '@/utils/language'

import Icon from '@/components/Icon'
import Page from '@/components/Page'

import DOMPurify from 'dompurify'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FAQ_LIST } from './faq'
import styles from './FaqPage.module.css'

type QuestionItemProps = {
  className?: string
  item: { question: string; answer: string }
}

function QuestionItem(props: QuestionItemProps) {
  const {
    className,
    item: { question, answer },
  } = props

  const [expand, setExpand] = useState(false)
  return (
    <div className={cx(styles.questionItem, className)}>
      <div
        className={styles.questionHeader}
        onClick={() => {
          setExpand(!expand)
        }}
      >
        <div className={styles.questionTitle}>{question}</div>
        <Icon
          className={styles.questionHeaderIcon}
          name={expand ? 'keyboardArrowUp' : 'keyboardArrowDown'}
        />
      </div>

      {expand && <div className={styles.questionAnswer} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />}
    </div>
  )
}

function FaqPage() {
  const T = useT()
  const navigate = useNavigate()

  return (
    <Page className={styles.page} title={T('help')}>
      <div className={styles.hint}>{T('faqHint')}</div>
      <button
        className={styles.howToButton}
        onClick={() => {
          navigate('/how-to')
        }}
      >
        {T('faqHowTo')}
      </button>
      {FAQ_LIST.map((section) => (
        <div key={section.title}>
          <div className={styles.sectionTitle}>{section.title}</div>
          {section.questions.map((item) => (
            <QuestionItem key={item.question} item={item} />
          ))}
        </div>
      ))}
    </Page>
  )
}

export default FaqPage
