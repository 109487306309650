import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useT } from '@/utils/language'

import Page from '@/components/Page'

import WaterDropImage from './images/water-drop.png'

import styles from './ErrorPage.module.css'

function ErrorPage() {
  const T = useT()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const errorCode = searchParams.get('code')
  const errorResource = searchParams.get('resource')

  const content = useMemo(() => {
    if (errorResource === 'Stand' && errorCode === 'not_found') {
      return {
        reason: T('errorStandNotFound'),
        detail: T('errorStandNotFoundDetail'),
      }
    }
    if (errorResource === 'Settlement' && errorCode === 'not_finished') {
      return {
        reason: T('errorSettlementNotFinished'),
        detail: T('errorSettlementNotFinishedDetail'),
      }
    }
    if (errorResource === 'Rental' && errorCode === 'limit_over') {
      return {
        reason: T('errorRentalLimitOver'),
        detail: T('errorRentalLimitOverDetail'),
      }
    }
    return null
  }, [T, errorCode, errorResource])

  if (!content) return null

  return (
    <Page className={styles.page}>
      <img className={styles.waterDropImage} src={WaterDropImage} alt="" />
      <div className={styles.title}>{T('errorTitle')}</div>
      <div className={styles.reason}>{content.reason}</div>

      <div className={styles.hr}>
        <div className={styles.hrLine} />
        {T('errorTryThisWay')}
        <div className={styles.hrLine} />
      </div>

      <div
        className={styles.detail}
        dangerouslySetInnerHTML={{ __html: content.detail }}
      />

      <button
        className={styles.tryButton}
        onClick={() => {
          navigate('/')
        }}
      >
        {T('tryAgain')}
      </button>

      <div className={styles.bottomButtonContainer}>
        <button
          className={styles.plainButton}
          onClick={() => {
            navigate('/')
          }}
        >
          {T('abandon')}
        </button>
        <button
          className={styles.plainButton}
          onClick={() => {
            navigate('/faq', { replace: true })
          }}
        >
          {T('faq')}
        </button>
      </div>
    </Page>
  )
}

export default ErrorPage
