import { useCallback, useEffect } from 'react'
import { login } from '@chargespot/spjs'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

import { useRequest } from '@/utils/request'
import { useFirebaseConfig } from '@/utils/env'
import { setStorageItem, getStorageItem } from './storage'

declare global {
  interface Window {
    setIkasaId: (ikasaId: string) => void
  }
}

export function useLogin() {
  const request = useRequest()
  const firebaseConfig = useFirebaseConfig()

  useEffect(() => {
    initializeApp(firebaseConfig)
  }, [firebaseConfig])

  // HACK: inject ikasaId setter
  useEffect(() => {
    window.setIkasaId = (ikasaId: string) => {
      setStorageItem<string>('ikasaId', ikasaId)
    }
  }, [])

  return useCallback(async () => {
    // developement ikasa id
    const storageIkasaId = getStorageItem<string>('ikasaId')
    if (storageIkasaId) {
      return { ikasaId: storageIkasaId }
    }

    const { code } = await login()
    const res = await request<{ customToken: string }>(
      '/v3/auth/spot_custom_token',
      { showLoading: false, data: { code } }
    )

    const auth = getAuth()
    const { user } = await signInWithCustomToken(auth, res.customToken)

    // TODO: maybe save the ikasaId into storage

    return { ikasaId: user.uid }
  }, [request])
}
