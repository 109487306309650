import cx from 'classnames'

import styles from './Indicator.module.css'

type IndecatorProps = {
  className?: string
  length: number
  current: number
  onChange: (next: number) => void
}

function Indicator(props: IndecatorProps) {
  const { className, length, current, onChange } = props
  return (
    <div className={cx(styles.root, className)}>
      {Array.from({ length }).map((_, index) => (
        <div
          key={index}
          className={cx(styles.dot, {
            [styles.currentDot]: index === current,
          })}
          onClick={() => onChange(index)}
        ></div>
      ))}
    </div>
  )
}

export default Indicator
