import cx from 'classnames'

import Icon, { IconName } from '@/components/Icon'

import styles from './IndexSideButton.module.css'

type IndexSideButtonProps = {
  className?: string
  icon: IconName
  text: string
  onClick?: () => void
}

function IndexSideButton(props: IndexSideButtonProps) {
  const { className, icon, text, onClick } = props
  return (
    <button className={cx(styles.button, className)} onClick={onClick}>
      <Icon className={styles.icon} name={icon} />
      {text}
    </button>
  )
}

export default IndexSideButton
