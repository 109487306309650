import cx from 'classnames'

import { useT } from '@/utils/language'

import Icon from '@/components/Icon'
import Page from '@/components/Page'

import Help11Image from './images/help-1-1.jpg'
import Help12Image from './images/help-1-2.jpg'
import Help21Image from './images/help-2-1.jpg'
import Help22Image from './images/help-2-2.jpg'
import Help31Image from './images/help-3-1.jpg'
import Help32Image from './images/help-3-2.jpg'
import Help41Image from './images/help-4-1.jpg'
import Help42Image from './images/help-4-2.jpg'
import Help43Image from './images/help-4-3.jpg'
import PaymentImage from './images/payment.jpg'
import PlanImage from './images/plan.svg'

import styles from './HowToPage.module.css'

type HelpStepProps = {
  className?: string
  current: number
  max: number
  text: string
}

function HelpStep(props: HelpStepProps) {
  const { className, current, max, text } = props
  return (
    <div className={cx(styles.step, className)}>
      <div className={styles.stepCurrent}>{current}</div>
      <div className={styles.stepMax}>/{max}</div>
      <div
        className={styles.stepText}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

type HelpImageProps = {
  className?: string
  image?: string
  text?: string
  textError?: boolean
}

function HelpImage(props: HelpImageProps) {
  const { className, image, text, textError = false } = props
  return (
    <div className={cx(styles.helpImage, className)}>
      {text && (
        <div
          className={cx(styles.helpImageText, {
            [styles.helpImageTextError]: textError,
          })}
        >
          {textError && (
            <Icon className={styles.helpImageTextIcon} name="close" />
          )}
          {text}
        </div>
      )}
      {image && <img className={styles.helpImageEntity} src={image} alt="" />}
    </div>
  )
}

function HowToPage() {
  const T = useT()

  return (
    <Page className={styles.page} title={T('manualDetail')}>
      <div className={styles.header}>{T('howToUseIkasa')}</div>
      <HelpStep
        className={styles.helpStep}
        current={1}
        max={4}
        text={T('findSpot')}
      />
      <div className={styles.helpImageContainer}>
        <HelpImage image={Help11Image} text={T('findSpot')} />
        <Icon className={styles.helpImageArrow} name="arrowRight" size={70} />
        <HelpImage image={Help12Image} text={T('findSpot')} />
      </div>

      <HelpStep
        className={styles.helpStep}
        current={2}
        max={4}
        text={T('useIkasaStep2')}
      />
      <div className={styles.helpImageContainer}>
        <HelpImage image={Help21Image} />
        <HelpImage
          image={Help22Image}
          text={T('useIkasaStep2Hint')}
          textError
        />
      </div>

      <HelpStep
        className={styles.helpStep}
        current={3}
        max={4}
        text={T('useIkasaStep3')}
      />
      <div className={styles.helpImageContainer}>
        <HelpImage image={Help31Image} />
        <HelpImage
          image={Help32Image}
          text={T('useIkasaStep3Hint')}
          textError
        />
      </div>

      <HelpStep
        className={styles.helpStep}
        current={4}
        max={4}
        text={T('useIkasaStep4')}
      />
      <div className={styles.helpImageContainer}>
        <HelpImage image={Help41Image} />
        <HelpImage image={Help42Image} text={T('useIkasaStep4Hint1')} />
        <HelpImage image={Help43Image} />
        <HelpImage
          className={styles.help44}
          text={T('useIkasaStep4Hint2')}
          textError
        />
      </div>

      <div className={styles.subHeader}>{T('returnIkasa')}</div>
      <div className={styles.text}>{T('returnIkasaHint')}</div>

      <div className={cx(styles.header, styles.middleHeader)}>
        {T('paymentPlan')}
      </div>
      <div className={styles.subHeader}>{T('paymentMethod')}</div>
      <div className={styles.text}>{T('paymentMethodText')}</div>
      <img className={styles.image} src={PaymentImage} alt="" />

      <div className={styles.subHeader}>{T('paymentTiming')}</div>
      <div className={styles.text}>{T('paymentTimingText')}</div>

      <div className={styles.subHeader}>{T('changePlan')}</div>
      <div className={styles.text}>{T('changePlanText1')}</div>
      <div className={styles.text}>{T('changePlanText2')}</div>

      <div className={styles.subHeader}>{T('subscriptionPlan')}</div>
      <div className={styles.text}>{T('subscriptionPlanText')}</div>
      <img className={styles.image} src={PlanImage} alt="" />
    </Page>
  )
}

export default HowToPage
