import cx from 'classnames'
import { format as formatDate } from 'date-fns'

import { Kasa } from '@/utils/kasa'
import { useT } from '@/utils/language'

import Icon from '@/components/Icon'

import styles from './ProfileKasaList.module.css'

type ProfileKasaListProps = {
  className?: string
  kasaList: Kasa[]
}

function ProfileKasaList(props: ProfileKasaListProps) {
  const { className, kasaList } = props
  const T = useT()

  return (
    <div className={cx(styles.container, className)}>
      {kasaList.map((kasa) => (
        <div key={kasa.ksid} className={styles.kasa}>
          <div className={styles.date}>
            <Icon name="beachAccess" className={styles.dateIcon} />
            {formatDate(new Date(kasa.rentedAt || ''), T('rentedAtFormat'))}
            {T('rentedAtSuffix')}
          </div>
          <div className={styles.info}>
            <div className={styles.id}>ID:{kasa.ksid}</div>
            <div className={styles.pin}>
              <Icon className={styles.pinIcon} name="lock" size={14} />
              {kasa.rentedScd}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProfileKasaList
