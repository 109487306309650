import { useEffect } from 'react'
import { setShareInfo, shareTo, showShareMenu } from '@chargespot/spjs'

import Modal from '@/components/Modal'

import { UIAction, useHandleUIAction } from '@/utils/uiAction'
import { Banner } from '@/utils/banner'
import { useT } from '@/utils/language'
import { Action } from '@/utils/action'

import styles from './UnlockCompleteUI.module.css'

import RentalCompleteImage from './images/rental-complete.png'
import ReturnCompleteImage from './images/return-complete.png'
import TwitterShareImage from './images/twitter-share.png'
import OtherShareImage from './images/other-share.png'

export type CompleteUI = {
  action: UIAction | null
  banner: Banner | null
  shareSns: {
    shareMessage: string
    twitterLink: string
  }
}

type UnlockCompleteUIProps = {
  action: Action
  ui: CompleteUI
  onClose: () => void
}

function UnlockCompleteUI(props: UnlockCompleteUIProps) {
  const {
    action,
    ui: { action: uiAction, shareSns },
    onClose,
  } = props
  const T = useT()
  const hanldeUIAction = useHandleUIAction()

  useEffect(() => {
    if (uiAction) {
      hanldeUIAction(uiAction)
    }
  }, [uiAction, hanldeUIAction])

  useEffect(() => {
    setShareInfo({
      title: shareSns.shareMessage,
      url: '',
    })
  }, [shareSns])

  return (
    <Modal visible mask={false} maskClosable={false}>
      <div className={styles.container}>
        <div className={styles.title}>
          {action === 'rental'
            ? T('rentalSuccessTitle')
            : T('returnSuccessTitle')}
        </div>

        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html:
              action === 'rental'
                ? T('rentalSuccessText')
                : T('returnSuccessText'),
          }}
        />

        <img
          className={styles.image}
          src={action === 'rental' ? RentalCompleteImage : ReturnCompleteImage}
          alt=""
        />

        <div className={styles.shareContainer}>
          <button
            className={styles.shareButton}
            onClick={() => {
              shareTo({ platform: 'twitter' })
            }}
          >
            <img
              className={styles.shareButtonImage}
              src={TwitterShareImage}
              alt="share to twitter"
            />
            <div>{T('twitter')}</div>
          </button>
          <button
            className={styles.shareButton}
            onClick={() => {
              showShareMenu()
            }}
          >
            <img
              className={styles.shareButtonImage}
              src={OtherShareImage}
              alt="share to other"
            />
            <div>{T('other')}</div>
          </button>
        </div>

        <button
          className={styles.closeButton}
          onClick={() => {
            onClose()
          }}
        >
          {T('close')}
        </button>
      </div>
    </Modal>
  )
}

export default UnlockCompleteUI
