import { useContext, createContext } from 'react'

export type Setting = {
  ikasaId: string | null | undefined
}

type SettingUpdater = (setting: Setting) => Setting

export type SettingContextType = {
  setting: Setting
  setSetting: (value: Setting | SettingUpdater) => void
}

export const defaultSetting: Setting = { ikasaId: undefined }

export const SettingContext = createContext<SettingContextType>({
  setting: defaultSetting,
  setSetting: () => {},
})

export const SettingProvider = SettingContext.Provider

export function useIkasaId() {
  const { setting } = useContext(SettingContext)
  return setting.ikasaId
}
