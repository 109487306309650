// https://admin-pre.charge-spot.com/country/all/open
export enum Country {
  HK = 84,
  JP = 99,
  MO = 132,
  MY = 140,
  TH = 191,
  TW = 199,
  CN = 217,
  US = 203,
}

export const CountryName: Record<Country, string> = {
  [Country.HK]: 'HK',
  [Country.JP]: 'JP',
  [Country.MO]: 'MO',
  [Country.MY]: 'MY',
  [Country.TH]: 'TH',
  [Country.TW]: 'TW',
  [Country.CN]: 'CN',
  [Country.US]: 'US',
}
