import { chooseCoupon, requestBindPayment } from '@chargespot/spjs'
import { useCallback, useState } from 'react'

import { useConfirm } from '@/utils/confirm'
import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'


import Page from '@/components/Page'
import PlanCard from '@/components/PlanCard'
import SubscriptionPlanModal from '@/components/SubscriptionPlanModal'

import { useToast } from '@/utils/toast'
import { useNavigate } from 'react-router-dom'
import styles from './PlanPage.module.css'

function PlanPage() {
  const T = useT()
  const navigate = useNavigate()
  const toast = useToast()
  const request = useRequest()
  const ikasaId = useIkasaId()
  const selectPlan = useCallback(
    async (plan: PlanType) => {
      if (plan === PlanType.Office) {
        navigate('/plan/office')
        return
      }

      // HACK: ikasa must change plan type enum string
      const planType = plan === PlanType.Normal ? 'charge' : plan

      try {
        const { alreadyRegistered: isPaymentRegistered } = await request<{ alreadyRegistered: boolean }>(`/service/v1/payment/spot/confirm/${ikasaId}`, {
          method: 'GET',
        });

        if (!isPaymentRegistered) {
          try {
            await requestBindPayment()
          } catch (e: any) {
            if (e.errCode !== -1) toast(e.errMsg)
            return Promise.reject(e)
          }
        }
      } catch (e) {
        return Promise.reject(e)
      }

      if (planType === PlanType.Subscription) {
        try {
          await new Promise(res => setTimeout(res, 1000));
          const chooseCouponPayload = await chooseCoupon()
          const coupon = chooseCouponPayload?.couponId
          if (coupon) {
            await request('/service/v1/campaign/coupon/spot', {
              method: 'POST',
              data: { ikasaId, couponId: coupon },
            })
          }
        } catch (e: any) {
          if (e.errCode !== -1) {
            toast(e.errMsg)
          }
          return Promise.reject(e)
        }
      }

      try {
        await request('/service/v1/setting/user/plan', {
          method: 'POST',
          data: { ikasaId, planType },
        });
      } catch (e) {
        return Promise.reject(e)
      }


      navigate('/')
    },
    [navigate, ikasaId, request, toast]
  )

  // subscription plan
  const [showSubPlanModal, setShowSubPlanModal] = useState(false)
  const onSubPlanModalConfirm = useCallback(() => {
    selectPlan(PlanType.Subscription)
  }, [selectPlan])

  // normal plan
  const confirm = useConfirm()
  const onNormalPlanClick = useCallback(async () => {
    await confirm({
      title: T('useNormalPlanConfirm'),
      content: T('useNormalPlanConfirmHint'),
    })
    selectPlan(PlanType.Normal)
  }, [T, confirm, selectPlan])

  // office plan
  const onOfficePlanClick = useCallback(async () => {
    selectPlan(PlanType.Office)
  }, [selectPlan])

  return (
    <Page className={styles.page} title={T('selectPlan')}>
      <div className={styles.title}>{T('pleaseSelectPlan')}</div>

      <PlanCard
        className={styles.card}
        type={PlanType.Subscription}
        onClick={() => setShowSubPlanModal(true)}
      />

      <PlanCard
        className={styles.card}
        type={PlanType.Normal}
        onClick={onNormalPlanClick}
      />

      <PlanCard
        className={styles.card}
        type={PlanType.Office}
        onClick={onOfficePlanClick}
      />

      <SubscriptionPlanModal
        visible={showSubPlanModal}
        onConfirm={onSubPlanModalConfirm}
        onBack={() => {
          setShowSubPlanModal(false)
        }}
      />
    </Page>
  )
}

export default PlanPage
