import { useMemo } from 'react'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'
import { useUser } from '@/utils/user'
import { useSetClipboardData } from '@/utils/device'

import Page from '@/components/Page'
import Icon from '@/components/Icon'
import Menu, { MenuItem } from '@/components/Menu'

import styles from './ProfileAccountPage.module.css'

function ProfileAccountPage() {
  const T = useT()
  const user = useUser()
  const setClipboardData = useSetClipboardData()

  const currentPlan = useMemo(() => {
    if (!user) return ''
    if (user.planType === PlanType.Subscription) return T('subscriptionPlan')
    if (user.planType === PlanType.Normal) return T('normalPlan')
    if (user.planType === PlanType.Office) return T('officePlan')
  }, [user, T])

  if (!user) return null

  return (
    <Page className={styles.page} title={T('accountInformation')}>
      <Menu className={styles.menu}>
        <MenuItem
          text={T('ikasaUserId')}
          hint={user?.ikasaId}
          border
          right={
            <button
              className={styles.copyButton}
              onClick={() => {
                setClipboardData(user?.ikasaId)
              }}
            >
              <Icon name="copy" />
            </button>
          }
        />
        <MenuItem text={T('currentPlan')} hint={currentPlan} border />
      </Menu>
    </Page>
  )
}

export default ProfileAccountPage
