import { useCallback, useEffect, useState } from 'react'

import { useIkasaId } from '@/utils/setting'
import { useRequest } from '@/utils/request'
import { PlanType } from '@/utils/plan'
import { Kasa } from '@/utils/kasa'

export interface User {
  alias: string
  createdAt: string
  deletedAt: string
  email: string
  facebookId: string
  googleId: string
  home_station: string
  id: number
  ikasaId: string
  lineId: string
  odakyuId: string
  planType: PlanType
  previousUserId: number
  subPlan: string
  termsAgreedAt: string
  tutorialCompletedAt: string
  updatedAt: string
  workStation: string
}

export interface Settlement {
  amount: number // 280
  nextMonthAmount: number // 280
  planType: PlanType // "subsc"
  rentalCount: number // 3
  settlementDate: string // "2022-04-01"
  subPlan: string // ""
}

export interface UserAmount {
  ikasaId: User['ikasaId']
  settlement: Settlement
  ui: {
    planColor: string // "#00ABD8"
    planName: string // "使い放題プラン"
  }
}

export interface HistoryItem {
  enSpotName: string // "【1行方不明】　　壊れ汚れは17へ／ストック傘は18へ"
  enTime: string //  "2022-03-15T12:27:51Z"
  id: number // 1
  stSpotName: string // "紛失傘　東京　上野以外　飯塚専用"
  stTime: string // "2019-06-06T09:24:37Z"
  totalTime: string // "24315:03"
}

export interface UserHistory {
  month: number // 202202
  data: HistoryItem[]
}

export function useUser() {
  const request = useRequest()
  const ikasaId = useIkasaId()

  const [user, setUser] = useState<User>()
  const getUser = useCallback(async () => {
    const user = await request<User>(`/service/v1/setting/user/${ikasaId}`)
    setUser(user)
  }, [request, ikasaId])

  useEffect(() => {
    getUser()
  }, [getUser])

  return user
}

export function useKasaCount(): [number | null, () => Promise<void>] {
  const request = useRequest()
  const ikasaId = useIkasaId()

  const [count, setCount] = useState<number | null>(null)
  const getCount = useCallback(async () => {
    const kasaList = await request<Kasa[]>(
      `/service/v1/return/confirm/${ikasaId}`
    )
    setCount(kasaList.length)
  }, [request, ikasaId])

  useEffect(() => {
    getCount()
  }, [getCount])

  return [count, getCount]
}
