import cx from 'classnames'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'

import Icon from '@/components/Icon'

import styles from './PlanCard.module.css'

type CustomPlanCardProps = {
  className?: string
  preset: 'subscription' | 'normal' | 'office'
  title: string
  titleHint?: string
  recommended?: boolean
  content?: string
  price?: string
  contentBottom?: React.ReactNode
  onClick?: () => void
}

function CustomPlanCard(props: CustomPlanCardProps) {
  const T = useT()
  const {
    className,
    preset,
    title,
    titleHint,
    recommended,
    content,
    price,
    contentBottom,
    onClick,
  } = props
  return (
    <div
      className={cx(styles.root, className, {
        [styles.subscription]: preset === 'subscription',
        [styles.normal]: preset === 'normal',
        [styles.office]: preset === 'office',
        [styles.noContent]: !content,
      })}
      onClick={onClick}
    >
      <div className={styles.header}>
        {recommended && (
          <div className={styles.recommended}>{T('recommended')}</div>
        )}
        <div className={styles.titleContainer}>
          <div className={styles.titleHint}>{titleHint}</div>
          <div className={styles.title}>{title}</div>
        </div>
        <Icon className={styles.headerIcon} name="chevronRight" />
      </div>

      {content && (
        <div className={styles.body}>
          <div className={styles.content}>{content}</div>
          <div className={styles.price}>{price}</div>
          {contentBottom}
        </div>
      )}
    </div>
  )
}

type PlanCardProps = {
  type: PlanType
  recommended?: boolean
  className?: string
  contentBottom?: React.ReactNode
  onClick?: () => void
}

function PlanCard(props: PlanCardProps) {
  const {
    type,
    recommended = type === PlanType.Subscription,
    className,
    onClick,
    contentBottom,
  } = props
  const T = useT()

  if (type === PlanType.Subscription) {
    return (
      <CustomPlanCard
        className={className}
        preset="subscription"
        title={T('subscriptionPlan')}
        titleHint={T('subscriptionPlanTitleHint')}
        content={T('subscriptionPlanContent')}
        price={T('subscriptionPlanPrice')}
        contentBottom={contentBottom}
        recommended={recommended}
        onClick={onClick}
      />
    )
  }

  if (type === PlanType.Normal) {
    return (
      <CustomPlanCard
        className={className}
        preset="normal"
        title={T('normalPlan')}
        titleHint={T('normalPlanTitleHint')}
        content={T('normalPlanContent')}
        price={T('normalPlanPrice')}
        contentBottom={contentBottom}
        onClick={onClick}
      />
    )
  }

  if (type === PlanType.Office) {
    return (
      <CustomPlanCard
        className={className}
        preset="office"
        title={T('officePlan')}
        contentBottom={contentBottom}
        onClick={onClick}
      />
    )
  }

  return null
}

export default PlanCard
