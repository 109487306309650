import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'
import { useRequest } from '@/utils/request'
import { useUser } from '@/utils/user'
import { useIkasaId } from '@/utils/setting'

import Page from '@/components/Page'
import PlanCard from '@/components/PlanCard'

import styles from './ProfilePlanPage.module.css'

function ProfilePlanPage() {
  const T = useT()
  const user = useUser()
  const navigate = useNavigate()

  const selectNextPlan = useCallback(
    (plan: PlanType) => {
      if (plan === PlanType.Office) {
        navigate('/plan/office')
        return
      }
      const query = new URLSearchParams({ next: plan }).toString()
      navigate(`/profile/plan/change?${query}`)
    },
    [navigate]
  )

  const availablePlan = useMemo(() => {
    const planList = [PlanType.Subscription, PlanType.Normal, PlanType.Office]
    if (!user) return planList
    return planList.filter((plan) => plan !== user.planType)
  }, [user])

  const request = useRequest()
  const ikasaId = useIkasaId()
  const [hasChageStockTask, setHasChargeStockTask] = useState(false)
  useEffect(() => {
    request(`/db/v1/user/to_charge_stock/${ikasaId}`, {
      showToast: false,
    }).then(() => {
      setHasChargeStockTask(true)
    })
  }, [request, ikasaId])

  if (!user) return null

  return (
    <Page className={styles.page} title={T('changePlan')}>
      <div className={styles.title}>{T('currentPlan')}</div>

      <PlanCard
        className={styles.currentPlan}
        type={user.planType}
        recommended={false}
        contentBottom={
          hasChageStockTask && (
            <div className={styles.currentPlanHint}>
              {T('alreadyAppliedChargeStock2')}
            </div>
          )
        }
      />

      <div className={styles.title}>{T('availablePlan')}</div>

      {availablePlan.map((plan) => (
        <PlanCard
          key={plan}
          className={styles.card}
          type={plan}
          onClick={() => {
            selectNextPlan(plan)
          }}
        />
      ))}
    </Page>
  )
}

export default ProfilePlanPage
