import {
  writeBLECharacteristicValue,
  notifyBLECharacteristicValueChange,
  onBLECharacteristicValueChange,
  offBLECharacteristicValueChange,
  getBLEDeviceCharacteristics,
} from '@chargespot/spjs'
import {
  Device,
  DeviceCharacteristic,
  DeviceService,
} from '@chargespot/spjs/es/bluetooth'

import {
  makeBLEPacket,
  readBLEPacket,
  encodePacket,
  decodePacket,
} from './packet'

export enum Command {
  Rental = 0x04,
  Return = 0x05,
  GetBatteryStatus = 0x08,
}

enum Response {
  Rental = 0x84,
  Return = 0x85,
  GetBatteryStatus = 0x88,
}

export function generateValue(command: Command) {
  const time = Math.floor(Date.now() / 1000) + 901214048
  const packet = makeBLEPacket(command, time)
  const packetString = encodePacket(packet)
  return packetString
}

export function parseValue(value: string) {
  const packet = decodePacket(value)
  const response = readBLEPacket(packet)
  return response
}

type HandleValueChange = Parameters<typeof onBLECharacteristicValueChange>[0]

type CommandOptions = {
  deviceId: string
  serviceId: string
  writeCharacteristicId: string
  notifyCharacteristicId: string
}

type WriteValueOptions = CommandOptions & {
  command: Command
  response: Response
}

function writeWithResponse(options: WriteValueOptions) {
  return new Promise<Uint8Array>((resolve) => {
    const {
      deviceId,
      serviceId,
      writeCharacteristicId,
      notifyCharacteristicId,
      command,
      response,
    } = options

    const handleValueChange: HandleValueChange = (res) => {
      const parsedValue = parseValue(res.value)
      if (parsedValue[0] === response) {
        offBLECharacteristicValueChange(handleValueChange)
        resolve(parsedValue)
      }
    }

    onBLECharacteristicValueChange(handleValueChange)

    notifyBLECharacteristicValueChange({
      deviceId,
      serviceId,
      characteristicId: notifyCharacteristicId,
      state: true,
    })

    const writeValue = generateValue(command)

    writeBLECharacteristicValue({
      deviceId,
      serviceId,
      characteristicId: writeCharacteristicId,
      value: writeValue,
    })
  })
}

enum Result {
  // 正常に成功したので、2つのリーダーを読み取った
  Success = 0x00,
  // 時間内にエリア1に到達しなかった=1枚も読み取りできなかった
  PositionOneNotRead = 0x01,
  // エリア2に到達しなかった=エリア1は読み取った
  PositionTwoNotRead = 0x02,
}

enum SwitchStatus {
  Neither = 0x00,
  SwitchOne = 0x01,
  SwitchTwo = 0x02,
  Both = 0x03,
}

type UnlockResponse = {
  // raw response
  result: Result
  switchStatus: SwitchStatus
  card1Id: string
  card2Id: string
  // generated response
  readTimes: number
  tagId: string
}

function parseUnlockResponse(response: Uint8Array): UnlockResponse {
  const result = response[2] as Result

  const switchStatus = response[3] as SwitchStatus

  const card1Id = Array.from(response.slice(4, 11))
    .map((n) => n.toString(16).padStart(2, '0'))
    .join('')

  const card2Id = Array.from(response.slice(4, 11))
    .map((n) => n.toString(16).padStart(2, '0'))
    .join('')

  let readTimes: number = 0
  if (result === Result.Success) readTimes = 2
  if (result === Result.PositionTwoNotRead) readTimes = 1
  if (result === Result.PositionOneNotRead) readTimes = 0

  let tagId = ''
  if (readTimes === 1) tagId = card1Id
  if (readTimes === 2) tagId = card2Id

  return {
    result,
    switchStatus,
    card1Id,
    card2Id,
    readTimes,
    tagId,
  }
}

export async function rentalKasa(options: CommandOptions) {
  const response = await writeWithResponse({
    deviceId: options.deviceId,
    serviceId: options.serviceId,
    writeCharacteristicId: options.writeCharacteristicId,
    notifyCharacteristicId: options.notifyCharacteristicId,
    command: Command.Rental,
    response: Response.Rental,
  })

  const unlockResponse = parseUnlockResponse(response)
  return unlockResponse
}

export async function returnKasa(options: CommandOptions) {
  const response = await writeWithResponse({
    deviceId: options.deviceId,
    serviceId: options.serviceId,
    writeCharacteristicId: options.writeCharacteristicId,
    notifyCharacteristicId: options.notifyCharacteristicId,
    command: Command.Return,
    response: Response.Return,
  })

  const unlockResponse = parseUnlockResponse(response)
  return unlockResponse
}

export async function getKasaBattery(options: CommandOptions) {
  const response = await writeWithResponse({
    deviceId: options.deviceId,
    serviceId: options.serviceId,
    writeCharacteristicId: options.writeCharacteristicId,
    notifyCharacteristicId: options.notifyCharacteristicId,
    command: Command.GetBatteryStatus,
    response: Response.GetBatteryStatus,
  })

  return response[3]
}

export async function findWriteNotifyService(
  device: Device,
  services: DeviceService[]
) {
  let service: DeviceService | null = null
  let writeChar: DeviceCharacteristic | null = null
  let notifyChar: DeviceCharacteristic | null = null

  for (let index = 0; index < services.length; index++) {
    const currentService = services[index]

    // Generic value could be omit
    // https://developers.weixin.qq.com/miniprogram/dev/framework/device/ble.html
    if (
      currentService.uuid.startsWith('00001800') ||
      currentService.uuid.startsWith('00001801')
    ) {
      continue
    }

    const { characteristics } = await getBLEDeviceCharacteristics({
      deviceId: device.deviceId,
      serviceId: currentService.uuid,
    })

    const serviceWriteChar = characteristics.find(
      (char) => char.properties.write
    )

    const serviceNotifyChar = characteristics.find(
      (char) => char.properties.notify
    )

    if (serviceWriteChar && serviceNotifyChar) {
      writeChar = serviceWriteChar
      notifyChar = serviceNotifyChar
      service = currentService
      break
    }
  }

  return { service, writeChar, notifyChar }
}
