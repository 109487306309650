import { useNavigate, useParams } from 'react-router-dom'

import Page from '@/components/Page'
import { useT } from '@/utils/language'
import { setStorageItem } from '@/utils/storage'
import { AutoAction } from '@/utils/action'

import styles from './ChooseActionPage.module.css'
import ConfirmModal from '@/components/ConfirmModal'

function ChooseActionPage() {
  const T = useT()
  const navigate = useNavigate()
  const { standId } = useParams<{ standId: string }>()

  if (!standId) return null

  return (
    <Page>
      {/* TODO: like water-drop background in unlock page */}
      <div className={styles.background}>
        <ConfirmModal
          onCloseButtonClick={() => {
            navigate('/', { replace: true })
          }}
          mask={false}
          title={T('chooseAction')}
          cancel={T('rental')}
          cancelIcon="beachAccess"
          cancelPreset="primary"
          onCancel={() => {
            setStorageItem<AutoAction>(
              'autoAction',
              { action: 'rental', standId },
              'session'
            )
            navigate('/', { replace: true })
          }}
          confirm={T('return')}
          confirmIcon="beachAccess"
          onConfirm={() => {
            setStorageItem<AutoAction>(
              'autoAction',
              { action: 'return', standId },
              'session'
            )
            navigate('/', { replace: true })
          }}
        />
      </div>
    </Page>
  )
}

export default ChooseActionPage
