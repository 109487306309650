import { Action } from '@/utils/action'

export interface Kasa {
  ksid: number
  pin: number
  rentedAt?: string
  rentedScd: number
  rentedSpotName: string
}

export interface LastAction {
  ikasaId: string
  ksid: number
  processId: string
  processedAt: string
  spotName: string
}

// It names very strange
// レンタル・返却申請確認が必要な対象
// https://docs.i-kasa.com/api/http/v2.html#/paths/~1share~1request~1ikasa_id~1{ikasa_id}/get
export interface ShareRequestPayload {
  currentShares: Kasa[] | null
  needSelect: boolean
  rental: LastAction | null
  return: LastAction | null
}

export type ShareRequestConfirmContent = {
  action: Action
  time: string
  spotName: string
  ksid: number
  processId: string
}

export function parseStandUrl(url: string) {
  return new URL(url).searchParams.get('id')
}
