import cx from 'classnames'

import Icon from '@/components/Icon'

import styles from './Checkbox.module.css'

type CheckboxProps = {
  className?: string
  checked?: boolean
  onChange?: (nextChecked: boolean) => void
}

function Checkbox(
  props: CheckboxProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof CheckboxProps>
) {
  const { className, checked = false, onChange, ...restProps } = props
  return (
    <label
      className={cx(styles.root, className, {
        [styles.checked]: checked,
      })}
    >
      {checked && <Icon className={styles.icon} name="check" size={18} />}

      {!checked && <div className={styles.unCheckedBorder}></div>}
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (onChange) onChange(!checked)
        }}
        {...restProps}
      />
    </label>
  )
}

export default Checkbox
