import cx from 'classnames'

import { Banner } from '@/utils/banner'
import { useSwiper } from '@/utils/swiper'
import { useHandleUIAction } from '@/utils/uiAction'

import styles from './IndexBanner.module.css'

type IndexBannerProps = {
  className?: string
  bannerList: Banner[]
}

function IndexBanner(props: IndexBannerProps) {
  const { className, bannerList } = props
  const { elementRef } = useSwiper({ interval: 3000 })
  const hanldeUIAction = useHandleUIAction()
  return (
    <div className={cx(styles.container, className)} ref={elementRef}>
      {bannerList.map((banner) => (
        <img
          className={styles.image}
          key={banner.id}
          src={banner.image}
          alt=""
          onClick={() => {
            hanldeUIAction(banner)
          }}
        />
      ))}
    </div>
  )
}

export default IndexBanner
