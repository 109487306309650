import { useNavigate } from 'react-router-dom'

import { useT } from '@/utils/language'
import { Action } from '@/utils/action'
import Icon from '@/components/Icon'

import styles from './UnlockGuideError.module.css'

type UnlockGuideErrorProps = {
  action: Action
  onRetry: () => void
}

function UnlockGuideError(props: UnlockGuideErrorProps) {
  const { action, onRetry } = props
  const T = useT()
  const navigate = useNavigate()

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>
          {action === 'rental'
            ? T('unlockGuideErrorTitleRental')
            : T('unlockGuideErrorTitleReturn')}
        </div>
        <div className={styles.titleHint}>{T('unlockGuideErrorHint')}</div>

        <div className={styles.step}>
          <div className={styles.stepIndex}>1</div>
          {T('unlockGuideErrorStep1')}
        </div>

        <div className={styles.step}>
          <div className={styles.stepIndex}>2</div>
          {T('unlockGuideErrorStep2')}
        </div>

        <div className={styles.step}>
          <div className={styles.stepIndex}>3</div>
          {action === 'rental'
            ? T('unlockGuideErrorStep3Rental')
            : T('unlockGuideErrorStep3Return')}
        </div>

        <button className={styles.tryButton} onClick={onRetry}>
          <Icon className={styles.tryButtonIcon} name="kasa" />
          {T('tryAgain')}
        </button>
      </div>

      <div className={styles.helpHint}>{T('unlockGuideErrorHelpHint')}</div>
      <div className={styles.buttonGroup}>
        <button
          className={styles.helpButton}
          onClick={() => {
            navigate('/faq', { replace: true })
          }}
        >
          {T('unlockGuideErrorHelp')}
        </button>
        <button
          className={styles.homeButton}
          onClick={() => {
            navigate('/')
          }}
        >
          {T('unlockGuideErrorHome')}
        </button>
      </div>
    </div>
  )
}

export default UnlockGuideError
