export enum PlanType {
  Subscription = 'subsc',
  Normal = '',
  Office = 'office',
}

export interface Plan {
  title: string
  titleHint?: string
  recommended?: boolean
}
