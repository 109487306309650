import { useCallback } from 'react'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { useApiOrigin, useApiAuthorization } from '@/utils/env'
import { useLoading } from '@/utils/loading'
import { useToast } from '@/utils/toast'
import { ResponseError, useGetErrorMessage } from '@/utils/error'

// show loading after debounce time
const LOADING_DEBOUNCE = 300

type RequestOptions<REQ> = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  data?: REQ
  showToast?: boolean
  showLoading?: boolean
}

type StringRecord = Record<string, string | number>

function stringifyDataValue(data: StringRecord) {
  const stringifyData: Record<string, string> = {}
  Object.keys(data).forEach((key) => {
    stringifyData[key] = String(data[key])
  })
  return stringifyData
}

export function useRequest() {
  const apiBase = useApiOrigin()
  const apiAuhtorization = useApiAuthorization()
  const toast = useToast()
  const [showLoading, hideLoading] = useLoading()
  const getErrorMessage = useGetErrorMessage()

  return useCallback(
    async function request<RES, REQ = any>(
      pathname: string,
      inputOptions: RequestOptions<REQ> = {}
    ) {
      // fetch options
      const options: RequestOptions<REQ> = {
        showToast: true,
        showLoading: true,
        ...inputOptions,
      }

      // fetch endpoint
      const inputPathname = pathname.replace(/^\//, '')
      const input = new URL(inputPathname, apiBase)

      // http headers
      const method = options.method || 'GET'

      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Authorization: apiAuhtorization,
      }
      const init: RequestInit = { method, headers }

      // fetch body
      if (['POST', 'PUT'].includes(method)) {
        const data = options.data as unknown as object
        init.body = JSON.stringify(decamelizeKeys(data))
      } else {
        const data = (options.data || {}) as unknown as object
        const stringifyData = stringifyDataValue(
          decamelizeKeys(data) as StringRecord
        )
        input.search = new URLSearchParams(stringifyData).toString()
      }

      // loading before fetch
      let loadingTimeout = null
      let loadingTask = null
      if (options.showLoading) {
        loadingTimeout = window.setTimeout(() => {
          loadingTask = showLoading()
        }, LOADING_DEBOUNCE)
      }

      // fetch
      const fetchResponse = await window.fetch(input.toString(), init)

      // loading after fetch
      if (options.showLoading) {
        if (loadingTimeout) {
          window.clearTimeout(loadingTimeout)
        }
        if (loadingTask) {
          hideLoading(loadingTask)
        }
      }

      // 200
      if (fetchResponse.ok) {
        const responseBody = (await fetchResponse.json()) as object
        return camelizeKeys(responseBody) as unknown as RES
      }

      // not 200 with valid body
      const fetchResponseBody = (await fetchResponse
        .json()
        .catch(() => null)) as ResponseError | null

      if (fetchResponseBody) {
        if (options.showToast) {
          const message = getErrorMessage(fetchResponseBody)
          toast(message, 'error')
        }

        return Promise.reject(fetchResponseBody)
      }

      return Promise.reject(fetchResponse)
    },
    [
      apiBase,
      showLoading,
      hideLoading,
      toast,
      getErrorMessage,
      apiAuhtorization,
    ]
  )
}
