import cx from 'classnames'

import ActiveImage from './images/active.png'
import GreyImage from './images/grey.png'

import styles from './KasaPin.module.css'

export type KasaPinProps = {
  className?: string
  active?: boolean
  selected?: boolean
  onClick?: () => void
}

function KasaPin(props: KasaPinProps) {
  const { className, active = true, selected = false, onClick } = props

  const image = active ? ActiveImage : GreyImage

  return (
    <img
      className={cx(styles.root, className, {
        [styles.selected]: selected,
      })}
      src={image}
      alt=""
      onClick={onClick}
    />
  )
}

export default KasaPin
