import { useMemo } from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { useT } from '@/utils/language'

import Icon, { IconName } from '@/components/Icon'

import styles from './BottomTab.module.css'

export type BottomTabItem = {
  icon: IconName
  text: string
  path: string
}

type CusttomBottomTabProps = {
  className?: string
  tabList: BottomTabItem[]
  currentTab: BottomTabItem | null
  onTabClick: (tab: BottomTabItem) => void
}

function CustomBottomTab(props: CusttomBottomTabProps) {
  const { className, tabList, currentTab, onTabClick } = props
  return (
    <div className={cx(styles.root, className)}>
      {tabList.map((tab) => (
        <button
          key={tab.path}
          className={cx(styles.tabItem, {
            [styles.currentTab]: currentTab === tab,
          })}
          onClick={() => {
            onTabClick(tab)
          }}
        >
          <Icon className={styles.icon} name={tab.icon} />
          <div className={styles.tabText}>{tab.text}</div>
        </button>
      ))}
    </div>
  )
}

export function getShowBottomTab(location: Location) {
  return (
    location.pathname === '/' ||
    location.pathname.startsWith('/chat') ||
    location.pathname.startsWith('/profile')
  )
}

type BottomTabProps = {
  className?: string
}

function BottomTab(props: BottomTabProps) {
  const { className } = props
  const T = useT()
  const location = useLocation()
  const navigate = useNavigate()
  const tabList = useMemo<BottomTabItem[]>(
    () => [
      { icon: 'home', text: T('home'), path: '/' },
      { icon: 'chat', text: T('chat'), path: '/chat' },
      { icon: 'accountCircle', text: T('myPage'), path: '/profile' },
    ],
    [T]
  )

  const currentTab =
    tabList.find(
      // well, hack for IndexPage
      (tab) =>
        tab.path === '/'
          ? tab.path === location.pathname
          : location.pathname.startsWith(tab.path)
    ) || null

  return (
    <CustomBottomTab
      className={className}
      tabList={tabList}
      currentTab={currentTab}
      onTabClick={(tab) => {
        navigate(tab.path)
      }}
    />
  )
}

export default BottomTab
