import cx from 'classnames'

import Modal from '@/components/Modal'
import { IconName } from '@/components/Icon'
import Button, { ButtonPreset } from '@/components/Button'
import { useT } from '@/utils/language'

import styles from './ConfirmModal.module.css'

export interface ConfirmProps {
  title?: React.ReactNode
  content?: React.ReactNode
  confirm?: string | null
  confirmIcon?: IconName
  confirmPreset?: ButtonPreset
  cancel?: string | null
  cancelIcon?: IconName
  cancelPreset?: ButtonPreset
  onConfirm?: () => void
  onCancel?: () => void
  confirmDisabled?: boolean
}

export interface ConfirmModalProps extends ConfirmProps {
  visible?: boolean
  onCloseButtonClick?: () => void
  mask?: boolean
  maskClosable?: boolean
}

function Confirm(props: ConfirmModalProps) {
  const T = useT()
  const {
    visible,
    onCloseButtonClick,
    mask,
    maskClosable = false,
    title,
    content,
    confirm = T('yes'),
    confirmIcon,
    confirmPreset = 'primaryDarkBlue',
    cancel = T('cancel'),
    cancelIcon,
    cancelPreset = 'white',
    onConfirm,
    onCancel,
    confirmDisabled = false,
  } = props

  return (
    <Modal
      visible={visible}
      mask={mask}
      maskClosable={maskClosable}
      onClose={onCancel}
      onCloseButtonClick={onCloseButtonClick}
    >
      <div className={styles.container}>
        {(title || content) && (
          <div className={styles.contentContainer}>
            {title && <div className={styles.title}>{title}</div>}
            {content && <div className={styles.content}>{content}</div>}
          </div>
        )}
        <div className={styles.buttonGroup}>
          {cancel && (
            <Button
              className={cx(styles.button, {
                [styles.singleButton]: confirm == null,
              })}
              preset={cancelPreset}
              icon={cancelIcon}
              text={cancel}
              onClick={onCancel}
            />
          )}
          {confirm && (
            <Button
              className={cx(styles.button, styles.confirmButton, {
                [styles.singleButton]: cancel == null,
              })}
              preset={confirmPreset}
              icon={confirmIcon}
              text={confirm}
              onClick={onConfirm}
              disabled={confirmDisabled}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default Confirm
