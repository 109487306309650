import { useCallback, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { setShareInfo, shareTo, showShareMenu } from '@chargespot/spjs'

interface ButtonAction {
  action: UIAction
  text: string
}

interface PageAction {
  title: string
  subtitle: string
  image: string
  content: string
  button: ButtonAction | null
}

interface FormAction {}

interface ModalAction {
  action: UIAction
  image: string
}

export interface UIAction {
  type: 'page' | 'modal' | 'route' | 'form' | 'link' | 'share'
  id: string
  link: string
  form: FormAction | null
  page: PageAction | null
  modal: ModalAction | null
  shareMessage: string
}

export type UIActionContextType = {
  uiAction: UIAction | null
  setUIAction: (uiAction: UIAction | null) => void
}

export const UIActionContext = createContext<UIActionContextType>({
  uiAction: null,
  setUIAction: () => {},
})

function convertActionLink(link: string) {
  if (link === '/home') return '/'

  return '/'
}

export function useHandleUIAction() {
  const navigate = useNavigate()
  const { setUIAction } = useContext(UIActionContext)
  return useCallback(
    async (uiAction: UIAction) => {
      if (uiAction.type === 'page') {
        const query = new URLSearchParams({ action: JSON.stringify(uiAction) })
        navigate(`/ui-action?${query.toString()}`)
        return
      }

      if (uiAction.type === 'route') {
        const link = convertActionLink(uiAction.link)
        navigate(link)
        return
      }

      if (uiAction.type === 'link' && uiAction.link) {
        window.open(uiAction.link)
        return
      }

      if (uiAction.type === 'modal') {
        setUIAction(uiAction)
        return
      }

      if (uiAction.type === 'share') {
        await setShareInfo({
          title: uiAction.shareMessage,
          url: '',
        })
        await showShareMenu()
      }

      throw new Error('not support current ui action')
    },
    [navigate, setUIAction]
  )
}
