import { useContext } from 'react'

import { useT } from '@/utils/language'
import { UIAction, UIActionContext, useHandleUIAction } from '@/utils/uiAction'

import Modal from '@/components/Modal'

import styles from './UIActionModal.module.css'

type UIActionModalProps = {
  action: UIAction
}

function UIActionModal(props: UIActionModalProps) {
  const T = useT()
  const { action } = props
  const hanldeUIAction = useHandleUIAction()
  const { setUIAction } = useContext(UIActionContext)

  if (action.type !== 'modal' || !action.modal) return null

  return (
    <Modal visible maskClosable={false}>
      <div className={styles.container}>
        {action.modal.image && (
          <div
            className={styles.imageContainer}
            onClick={() => {
              if (action.modal?.action) {
                hanldeUIAction(action.modal.action)
              }
            }}
          >
            <img className={styles.image} src={action.modal.image} alt="" />
          </div>
        )}
        <button
          className={styles.button}
          onClick={() => {
            setUIAction(null)
          }}
        >
          {T('close')}
        </button>
      </div>
    </Modal>
  )
}

export default UIActionModal
