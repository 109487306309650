import { createContext, useCallback, useContext, useEffect } from 'react'

import { ConfirmProps } from '@/components/ConfirmModal'

export type ConfirmContextType = {
  confirm: ConfirmProps | null
  setConfirm: (confirm: ConfirmProps | null) => void
}

export const ConfirmContext = createContext<ConfirmContextType>({
  confirm: null,
  setConfirm: () => { },
})

export const ConfirmProvider = ConfirmContext.Provider

export function useConfirm() {
  const { setConfirm } = useContext(ConfirmContext)

  useEffect(() => {
    return () => {
      setConfirm(null)
    }
  }, [setConfirm])

  return useCallback(
    (
      confirm: Pick<ConfirmProps, 'title' | 'content' | 'confirm' | 'cancel'>
    ) => {
      return new Promise<true>((resolve, reject) => {
        setConfirm({
          title: confirm.title,
          content: confirm.content,
          confirm: confirm.confirm,
          cancel: confirm.cancel,
          onConfirm: () => {
            setConfirm(null)
            resolve(true)
          },
          onCancel: () => {
            setConfirm(null)
            reject(false)
          },
        })
      })
    },
    [setConfirm]
  )
}