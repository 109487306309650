import { useEffect, useState } from 'react'
import cx from 'classnames'

import { useT } from '@/utils/language'
import { getStorageItem, setStorageItem } from '@/utils/storage'

import Icon from '@/components/Icon'

import styles from './IndexKasaCount.module.css'

const MAX_COUNT = 2

type IndexKasaCountProps = {
  count: number
  className?: string
}

function IndexKasaCount(props: IndexKasaCountProps) {
  const T = useT()
  const { count, className } = props

  const [hide, setHide] = useState(true)
  useEffect(() => {
    const hide = getStorageItem<true>('hideIndexKasaCount', 'session')
    if (hide) return
    setHide(false)
  }, [])

  if (hide) return null

  return (
    <div className={cx(styles.container, className)}>
      <Icon name="beachAccess" />
      <div className={styles.count}>
        <div className={styles.currentCount}>{count}</div>
        <div>
          /{MAX_COUNT}
          {T('kasaUnit')}
        </div>
      </div>
      <div className={styles.countText}>{T('notRentingKasa')}</div>
      <button
        className={styles.closeButton}
        onClick={() => {
          setHide(true)
          setStorageItem('hideIndexKasaCount', true, 'session')
        }}
      >
        <Icon name="close" />
      </button>
    </div>
  )
}

export default IndexKasaCount
