import { SnackBarPreset } from '@/components/SnackBar'
import {
  useContext,
  useCallback,
  createContext,
  useRef,
  useEffect,
} from 'react'

type ToastProps = {
  content: string
  preset: SnackBarPreset
  duration: number
}

export type ToastContextType = {
  toast: ToastProps | null
  setToast: (toast: ToastProps | null) => void
}

export const ToastContext = createContext<ToastContextType>({
  toast: null,
  setToast: () => {},
})

export const ToastProvider = ToastContext.Provider

export function useToast() {
  const { toast, setToast } = useContext(ToastContext)

  const toastTimeout = useRef<number | null>()
  useEffect(() => {
    if (!toast) return
    if (!toast.duration) return
    if (toastTimeout.current) {
      window.clearTimeout(toastTimeout.current)
    }
    toastTimeout.current = window.setTimeout(() => {
      toastTimeout.current = null
      setToast(null)
    }, toast.duration)
  }, [toast, setToast])

  return useCallback(
    (
      content: string,
      preset: SnackBarPreset = 'info',
      duration: number = 1500
    ) => {
      setToast({ content, preset, duration })
    },
    [setToast]
  )
}
