import { useCallback } from 'react'
import {
  permit as spotPermit,
  getSetting as spotGetSetting,
} from '@chargespot/spjs'
import { Scope } from '@chargespot/spjs/es/permission'

export function usePermit() {
  return useCallback(async (scope: string) => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      return Promise.resolve()
    }

    return spotPermit({ scope: scope as Scope })
  }, [])
}

export function useGetSetting() {
  return useCallback(async () => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      return { permissionSetting: { 'scope.bluetooth': false } }
    }

    return spotGetSetting()
  }, [])
}
