import cx from 'classnames'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'

import styles from './PlanPriceCard.module.css'

type CustomPlanPriceCardProps = {
  className?: string
  preset: 'subscription' | 'normal'
  hint: string
  value: string
}

function CustomPlanPriceCard(props: CustomPlanPriceCardProps) {
  const T = useT()
  const { className, preset, hint, value } = props
  return (
    <div
      className={cx(styles.container, className, {
        [styles.subscription]: preset === 'subscription',
        [styles.normal]: preset === 'normal',
      })}
    >
      <div className={styles.hint}>{hint}</div>
      <div className={styles.valueContainer}>
        <div className={styles.value}>{value}</div>
        <div className={styles.tax}>{T('withTax')}</div>
      </div>
    </div>
  )
}

type PlanPriceCardProps = {
  type: PlanType
  className?: string
}

function PlanPriceCard(props: PlanPriceCardProps) {
  const { type, className } = props
  const T = useT()

  if (type === PlanType.Subscription) {
    return (
      <CustomPlanPriceCard
        className={className}
        preset="subscription"
        hint={T('subscriptionPlanPriceHint')}
        value={T('subscriptionPlanPriceValue')}
      />
    )
  }

  if (type === PlanType.Normal) {
    return (
      <CustomPlanPriceCard
        className={className}
        preset="normal"
        hint={T('normalPlanPriceHint')}
        value={T('normalPlanPriceValue')}
      />
    )
  }

  return null
}

export default PlanPriceCard
