import WalkthroughPage from '@/pages/WalkthroughPage'
import PlanPage from '@/pages/PlanPage'
import PlanOfficePage from '@/pages/PlanOfficePage'
import ErrorPage from '@/pages/ErrorPage'
import UIActionPage from '@/pages/UIActionPage'
import ChooseActionPage from '@/pages/ChooseActionPage'
import IndexPage from '@/pages/IndexPage'
import SpotPage from '@/pages/SpotPage'
import ChatPage from '@/pages/ChatPage'
import HowToPage from '@/pages/HowToPage'
import FaqPage from '@/pages/FaqPage'
import CouponRedeemPage from './pages/CouponRedeemPage'
import KasaLostPage from './pages/KasaLostPage'
import UnlockPage from './pages/UnlockPage'
import ProfilePage from '@/pages/ProfilePage'
import ProfileAmountPage from '@/pages/ProfileAmountPage'
import ProfileHistoryPage from '@/pages/ProfileHistoryPage'
import ProfilePlanPage from '@/pages/ProfilePlanPage'
import ProfilePlanChangePage from '@/pages/ProfilePlanChangePage'
import ProfileAccountPage from '@/pages/ProfileAccountPage'

const routes = [
  {
    name: 'WalkthroughPage',
    path: '/walkthrough',
    component: WalkthroughPage,
  },
  {
    name: 'PlanPage',
    path: '/plan',
    component: PlanPage,
  },
  {
    name: 'PlanOfficePage',
    path: '/plan/office',
    component: PlanOfficePage,
  },
  {
    name: 'ErrorPage',
    path: '/error',
    component: ErrorPage,
  },
  {
    name: 'UIActionPage',
    path: '/ui-action',
    component: UIActionPage,
  },
  {
    name: 'ChooseActionPage',
    path: '/choose-action/:standId',
    component: ChooseActionPage,
  },
  {
    name: 'IndexPage',
    path: '/',
    component: IndexPage,
  },
  {
    name: 'SpotPage',
    path: '/spot/:scd',
    component: SpotPage,
  },
  {
    name: 'ChatPage',
    path: '/chat',
    component: ChatPage,
  },
  {
    name: 'HowToPage',
    path: '/how-to',
    component: HowToPage,
  },
  {
    name: 'FaqPage',
    path: '/faq',
    component: FaqPage,
  },
  {
    name: 'CouponRedeemPage',
    path: '/coupon-redeem',
    component: CouponRedeemPage,
  },
  {
    name: 'KasaLostPage',
    path: '/kasa-lost',
    component: KasaLostPage,
  },
  {
    name: 'UnlockPage',
    path: '/unlock/:standId',
    component: UnlockPage,
  },
  {
    name: 'ProfilePage',
    path: '/profile',
    component: ProfilePage,
  },
  {
    name: 'ProfileAmountPage',
    path: '/profile/amount',
    component: ProfileAmountPage,
  },
  {
    name: 'ProfileHistoryPage',
    path: '/profile/history',
    component: ProfileHistoryPage,
  },
  {
    name: 'ProfilePlanPage',
    path: '/profile/plan',
    component: ProfilePlanPage,
  },
  {
    name: 'ProfilePlanChangePage',
    path: '/profile/plan/change',
    component: ProfilePlanChangePage,
  },
  {
    name: 'ProfileAccountPage',
    path: '/profile/account',
    component: ProfileAccountPage,
  },
]

export default routes
