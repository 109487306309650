import { useCallback, useState } from 'react'

import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'

import Page from '@/components/Page'

import styles from './PlanOfficePage.module.css'

function PlanOfficePage() {
  const T = useT()
  const ikasaId = useIkasaId()
  const request = useRequest()

  const [officeCode, setOfficeCode] = useState('')
  const onSubmit = useCallback(() => {
    request('/service/v1/setting/user/plan', {
      method: 'POST',
      data: { ikasaId, officeCode },
    })
  }, [request, ikasaId, officeCode])

  return (
    <Page className={styles.page} title={T('officePlanSignUp')}>
      <div className={styles.title}>{T('officePlanCode')}</div>
      <input
        className={styles.input}
        value={officeCode}
        placeholder={T('inputText')}
        onChange={(e) => {
          setOfficeCode(e.target.value)
        }}
      />
      <button className={styles.button} onClick={onSubmit}>
        {T('planSignUp')}
      </button>
    </Page>
  )
}

export default PlanOfficePage
