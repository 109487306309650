import styles from './ModalBackdrop.module.css'

type ModalBackdropProps = {
  onClick?: () => void
}

function ModalBackdrop(props: ModalBackdropProps) {
  const { onClick } = props
  return <div className={styles.backdrop} onClick={onClick} />
}

export default ModalBackdrop
