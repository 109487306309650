import { useMemo } from 'react'

import { useT } from '@/utils/language'

import Modal from '@/components/Modal'
import Icon from '@/components/Icon'
import { PlanType } from '@/utils/plan'

import PlanPriceCard from '@/components/PlanPriceCard'

import styles from './SubscriptionPlanModal.module.css'

type SubscriptionPlanModalProps = {
  visible: boolean
  onConfirm: () => void
  onBack: () => void
}

function SubscriptionPlanModal(props: SubscriptionPlanModalProps) {
  const { visible, onConfirm, onBack } = props
  const T = useT()

  const guidePointList = useMemo<string[]>(
    () => [
      T('subscriptionPlanGuidePoint1'),
      T('subscriptionPlanGuidePoint2'),
      T('subscriptionPlanGuidePoint3'),
    ],
    [T]
  )

  return (
    <Modal visible={visible} onClose={onBack}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon className={styles.headerIcon} name="beachAccess" />
          {T('subscriptionPlan')}
        </div>

        <div className={styles.body}>
          <div className={styles.guide}>{T('subscriptionPlanGuide')}</div>
          <div className={styles.guidePointList}>
            {guidePointList.map((guidePoint, index) => (
              <div key={index} className={styles.guidePoint}>
                <Icon className={styles.guidePointIcon} name="check" />
                <div className={styles.guidePointText}>{guidePoint}</div>
              </div>
            ))}
          </div>

          <PlanPriceCard
            className={styles.priceCard}
            type={PlanType.Subscription}
          />

          <div className={styles.hint}>{T('subscriptionPlanHint')}</div>
          <button className={styles.confirmButton} onClick={onConfirm}>
            {T('usesubscriptionPlan')}
          </button>
          <button className={styles.backButton} onClick={onBack}>
            {T('back')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SubscriptionPlanModal
