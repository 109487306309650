import cx from 'classnames'

import styles from './SnackBar.module.css'

export type SnackBarPreset = 'error' | 'success' | 'info'

type SnackBarProps = {
  className?: string
  content: string
  preset?: SnackBarPreset
}

function SnackBar(props: SnackBarProps) {
  const { className, content, preset = 'info' } = props
  return (
    <div
      className={cx(styles.root, className, {
        [styles.error]: preset === 'error',
        [styles.success]: preset === 'success',
        [styles.info]: preset === 'info',
      })}
    >
      {content}
    </div>
  )
}

export default SnackBar
