import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { format as formatDate } from 'date-fns'

import { useT } from '@/utils/language'
import { Kasa } from '@/utils/kasa'

import Modal from '@/components/Modal'

import styles from './KasaLostModal.module.css'
import Checkbox from '@/components/Checkbox'

type KasaLostModalProps = {
  kasa: Kasa
  onSubmit: (kasa: Kasa) => void
  onCancel: () => void
}

function KasaLostModal(props: KasaLostModalProps) {
  const T = useT()
  const navigate = useNavigate()
  const { kasa, onCancel, onSubmit } = props

  const [agree, setAgree] = useState(false)

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.confirmTitle}>{T('kasaLostConfirmTitle')}</div>
        <div className={styles.kasaInfo}>
          <div>
            {formatDate(new Date(kasa.rentedAt || ''), T('rentedAtFormat'))}
            {T('rentedAtSuffix')}
          </div>
          <div>ID:{kasa.ksid}</div>
          <div>{T('kasaLostConfirmPrice')}</div>
        </div>
        <div className={styles.title}>{T('kasaLostRule')}</div>
        <div className={styles.text}>{T('kasaLostRuleText')}</div>

        <label className={styles.label}>
          <Checkbox
            className={styles.checkbox}
            checked={agree}
            onChange={setAgree}
          />
          {T('kasaLostCheck')}
        </label>

        <button
          className={cx(styles.button, styles.submitButton)}
          onClick={() => {
            onSubmit(kasa)
          }}
          disabled={!agree}
        >
          {T('lostApply')}
        </button>

        <div className={styles.buttonGroup}>
          <button className={styles.button} onClick={onCancel}>
            {T('cancel')}
          </button>
          <button
            className={cx(styles.button, styles.faqButton)}
            onClick={() => {
              navigate('/how-to')
            }}
          >
            {T('faq')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default KasaLostModal
