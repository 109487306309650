import cx from 'classnames'

import Icon, { IconName } from '@/components/Icon'

import styles from './Button.module.css'

export type ButtonPreset = 'white' | 'primary' | 'primaryDarkBlue'

type ButtonProps = {
  className?: string
  preset?: ButtonPreset
  icon?: IconName
  text: string
  onClick?: () => void
  disabled?: boolean
}

function Button(props: ButtonProps) {
  const {
    className,
    preset = 'primary',
    icon,
    text,
    onClick,
    disabled = false,
  } = props

  return (
    <button
      className={cx(styles.button, className, {
        [styles.white]: preset === 'white',
        [styles.primary]: preset === 'primary',
        [styles.primaryDarkBlue]: preset === 'primaryDarkBlue',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <Icon className={styles.icon} name={icon} />}
      {text}
    </button>
  )
}

export default Button
