import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Routes, Route } from 'react-router'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import cx from 'classnames'
import { formatISO } from 'date-fns'
import { getLaunchOptions } from '@chargespot/spjs'

import {
  ConfirmContext,
  ConfirmContextType,
  ConfirmProvider,
  useConfirm,
} from '@/utils/confirm'
import {
  defaultSetting,
  Setting,
  SettingContext,
  SettingProvider,
} from '@/utils/setting'
import {
  ToastContext,
  ToastContextType,
  ToastProvider,
  useToast,
} from '@/utils/toast'
import { useLogin } from '@/utils/login'
import { User } from '@/utils/user'
import { useRequest } from '@/utils/request'
import { LoadingContext, LoadingProvider } from '@/utils/loading'
import { UIAction, UIActionContext } from '@/utils/uiAction'
import { useT } from '@/utils/language'
import { parseStandUrl } from '@/utils/kasa'

import LoadingModal from '@/components/LoadingModal'
import ConfirmModal from '@/components/ConfirmModal'
import UIActionModal from '@/components/UIActionModal'
import BottomTab, { getShowBottomTab } from '@/components/BottomTab'
import SnackBar from '@/components/SnackBar'

import routes from '@/routes'

import '@/styles/vars.css'
import styles from './App.module.css'

function AppContent() {
  const request = useRequest()
  const T = useT()
  const { confirm: confirmState } = useContext(ConfirmContext)
  const confirm = useConfirm()
  const { uiAction } = useContext(UIActionContext)
  const { toast } = useContext(ToastContext)
  const { loading } = useContext(LoadingContext)
  const { setSetting } = useContext(SettingContext)
  const login = useLogin()
  const navigate = useNavigate()
  const showToast = useToast()

  const setUser = useCallback(async () => {
    const { ikasaId } = await login()
    setSetting((s: Setting) => ({ ...s, ikasaId }))

    // check user status
    return request<User>(`/service/v1/setting/user/${ikasaId}`, {
      showToast: false,
    }).then(
      (user) => ({ user, isNew: false }),
      async (e) => {
        if (!e.message?.includes('404')) {
          showToast(e)
          return Promise.reject(e)
        }

        // HACK: show confirm and plan page
        setInited(true)

        // navigate background page below confirm
        navigate('/walkthrough', { replace: true })

        // agree confirm
        await confirm({
          title: T('ikasa'),
          content: (
            <>
              {T('agreementHint')}
              {'\n\n'}
              <a href="https://www.i-kasa.com/terms">{T('userAgreement')}</a>
              {'\n'}
              <a href="https://www.i-kasa.com/privacy">{T('privacyPolicy')}</a>
            </>
          ),
          cancel: null,
          confirm: T('agreementConfirm'),
        })

        // create new user
        const user = await request<User>(`/service/v1/setting/user`, {
          method: 'POST',
          data: { ikasaId, termsAgreedAt: formatISO(new Date()) },
        })

        // update new user with spot payment
        await request('/service/v1/setting/payment/spot', {
          method: 'POST',
          data: { ikasaId },
        })
        return { user, isNew: true }
      }
    )
  }, [T, confirm, request, login, setSetting, navigate, showToast])

  const launchOptionsJumped = useRef(false)
  const setLaunchOptions = useCallback(async () => {
    if (launchOptionsJumped.current) return
    launchOptionsJumped.current = true

    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) return
    const launchOption = await getLaunchOptions()

    // Scene.Scan
    if (launchOption?.scene === 1011) {
      const scanPayload = launchOption.query as { result: string }
      const standId = parseStandUrl(scanPayload.result)
      if (!standId) return

      navigate(`/choose-action/${standId}`)
      return
    }

    // Scene.Map
    if (launchOption?.scene === 1146) {
      const scd = (launchOption.query as { merchantPoiId: string })
        .merchantPoiId

      navigate(`/spot/${scd}`)
      return
    }

    // Scene.OrderDetail
    if (launchOption?.scene === 1151) {
      navigate(`/profile/history`)
      return
    }

    // Scene.UsingOrder
    if (launchOption?.scene === 2000) {
      navigate(`/profile`)
      return
    }

    // Scene.Help
    if (launchOption?.scene === 2004) {
      navigate(`/chat`)
      return
    }
  }, [navigate])

  const [inited, setInited] = useState(false)
  const init = useCallback(async () => {
    const { isNew } = await setUser()
    if (!isNew) {
      await setLaunchOptions()
    }
    setInited(true)
  }, [setUser, setLaunchOptions])

  useEffect(() => {
    init()
  }, [init])

  const location = useLocation()
  const showBottomTab = getShowBottomTab(location)

  // wating for inited
  if (!inited) return null

  return (
    <div id="app" className={styles.root}>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>

      {confirmState && (
        <ConfirmModal
          title={confirmState.title}
          content={confirmState.content}
          confirm={confirmState.confirm}
          cancel={confirmState.cancel}
          onConfirm={confirmState.onConfirm}
          onCancel={confirmState.onCancel}
        />
      )}

      {uiAction && <UIActionModal action={uiAction} />}

      {toast && (
        <SnackBar
          className={cx(styles.snackBar, {
            [styles.snackBarWithBottomTab]: showBottomTab,
          })}
          content={toast.content}
          preset={toast.preset}
        />
      )}

      {loading.length > 0 && <LoadingModal />}

      {showBottomTab && <div className={styles.bottomTabHolder}></div>}
      {showBottomTab && <BottomTab className={styles.bottomTab} />}
    </div>
  )
}

function App() {
  const [setting, setSetting] = useState<Setting>(defaultSetting)
  const [confirm, setConfirm] = useState<ConfirmContextType['confirm']>(null)
  const [toast, setToast] = useState<ToastContextType['toast']>(null)
  const [loading, setLoading] = useState<symbol[]>([])
  const [uiAction, setUIAction] = useState<UIAction | null>(null)

  return (
    <BrowserRouter>
      <HelmetProvider>
        <SettingProvider value={{ setting, setSetting }}>
          <ConfirmProvider value={{ confirm, setConfirm }}>
            <ToastProvider value={{ toast, setToast }}>
              <LoadingProvider value={{ loading, setLoading }}>
                <UIActionContext.Provider value={{ uiAction, setUIAction }}>
                  <AppContent />
                </UIActionContext.Provider>
              </LoadingProvider>
            </ToastProvider>
          </ConfirmProvider>
        </SettingProvider>
      </HelmetProvider>
    </BrowserRouter>
  )
}

export default App
