import { useNavigate } from 'react-router-dom'

import { useT } from '@/utils/language'

import ReadErrorKasaImage from './images/read-error-kasa.png'

import styles from './UnlockError.module.css'

type UnlockErrorType = 'timeout' | 'unknown' | 'read'

type UnlockErrorProps = {
  type: UnlockErrorType
  onRetry: () => void
}

function useErrorText(type: UnlockErrorType) {
  const T = useT()

  if (type === 'read') {
    return {
      title: T('unlockErrorReadTitle'),
      content: (
        <>
          {T('unlockErrorReadContentPart1')}
          <img
            className={styles.readErrorKasaImage}
            src={ReadErrorKasaImage}
            alt=""
          />
          {T('unlockErrorReadContentPart2')}
        </>
      ),
      showNavigationButton: true,
    }
  }

  if (type === 'timeout') {
    return {
      title: T('unlockErrorTimeoutTitle'),
      content: T('unlockErrorTimeoutContent'),
      showNavigationButton: true,
    }
  }

  return {
    title: T('unlockErrorUnknownTitle'),
    content: T('unlockErrorUnknownContent'),
    showNavigationButton: false,
  }
}

function UnlockError(props: UnlockErrorProps) {
  const { type, onRetry } = props
  const T = useT()
  const navigate = useNavigate()
  const { title, content, showNavigationButton } = useErrorText(type)

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.contentHint}>{T('unlockErrorTitleStep')}</div>
      <div className={styles.content}>{content}</div>

      <button className={styles.tryButton} onClick={onRetry}>
        {T('tryAgain')}
      </button>

      {showNavigationButton && (
        <div className={styles.bottomButtonContainer}>
          <button
            className={styles.plainButton}
            onClick={() => {
              navigate('/')
            }}
          >
            {T('abandon')}
          </button>
          <button
            className={styles.plainButton}
            onClick={() => {
              navigate('/faq', { replace: true })
            }}
          >
            {T('faq')}
          </button>
        </div>
      )}
    </div>
  )
}

export default UnlockError
