// TODO: it should be responsed by back-end
export const FAQ_LIST = [
  {
    title: '首都圏・関西・名古屋・岡山(通信式カサ)',
    questions: [
      {
        question: 'どうやって返却するのですか？',
        answer:
          '返却スポットにあるPOPの「返却用QRコードをスキャン」を読み取り、画面に従ってカサをカサ立てに通すと返却完了となります。',
      },
      {
        question:
          '傘をレンタル/返却できません。\n（エラーが出る/画面が遷移しない）',
        answer:
          'アプリが最新バージョンになっているか確認してください。\n\n一度アプリを閉じて、再起動してください。\n\nBluetoothと位置情報をオンにして、傘はロック部分から離してから再度QRスキャンして下さい。\n\n環境により接続に最大30秒ほどかかる場合があります。\n\nレンタル動画は本ページ上部のボタンよりご覧ください。',
      },
      {
        question: '返却したのにレンタル中になる。',
        answer:
          '返却処理をさせていただきますので、アプリ下部メニューの問い合わせページにて下記3点をお知らせください。\n\n・ユーザーID\n・返却したスポット\n・カサID（５桁の数字）',
      },
      {
        question: 'アプリのバージョンはどこで確認するの？',
        answer:
          'マイページ内の「アカウント情報」よりご確認いただけます。\nまた、App Store/Google Playからもご確認できます。最新バージョンへ更新お願いします。',
      },
    ],
  },
  {
    title: '福岡・佐賀・水戸・札幌(ダイヤル式カサ)',
    questions: [
      {
        question: 'どうやって返却するのですか？',
        answer:
          '返却スポットにあるPOPの返却用QRコードを読み取りましたら返却完了となります。',
      },
      {
        question: 'ダイヤル式カサが開きません。',
        answer:
          'スポットの近くにいらっしゃる場合、お手数ではございますが、隣のカサをレンタルし、開かないカサはキャンセル処理いたしますのでカサのIDをアプリ下部メニューの問い合わせページにてご連絡ください。\n別のカサのレンタルが難しい場合も、アプリ下部メニューの問い合わせページにてご連絡ください。',
      },
      {
        question: 'カサの真ん中のダイヤルがゆるいです。',
        answer:
          '真ん中のダイヤルはあえてゆるく設計しております。\nお手数ですがしっかりダイヤルを合わせて解錠してみてください。',
      },
      {
        question: '返却QRコードを読み取り忘れて返却してしまいました。',
        answer:
          'システムより返却処理を致しますので返却場所と返却日時をアプリ下部メニューの問い合わせページにてご連絡ください。',
      },
    ],
  },
  {
    title: 'ヘルプ',
    questions: [
      {
        question: '借りた場所以外で返してもいいですか？',
        answer:
          '返却スポットは全国どちらのスポットでも大丈夫です。\nお好きなスポットにご返却ください。',
      },
      {
        question: 'スポットはどこにありますか？',
        answer:
          'アプリ左下にあるマップからお近くのスポットを確認することができます。',
      },
      {
        question: 'マップが正常に表示されません。',
        answer:
          '一度アプリを終了していただき、再度開いてください。\nそれでも解決しない場合は、アプリ下部メニューの問い合わせページにてご連絡ください。',
      },
      {
        question: '正常に返却できているかわかりません。',
        answer:
          'マイページの「ご利用詳細」→「利用履歴」よりレンタル開始時刻・スポットと終了時刻・スポットが確認できます。',
      },
      {
        question: '借りたカサが壊れていました',
        answer:
          'スポットの近くにいらっしゃる場合、お手数ではございますが、隣のカサをレンタルし、壊れたカサはキャンセル処理いたしますのでカサのIDをアプリ下部メニューの問い合わせページにてご連絡ください。\n別のカサのレンタルが難しい場合も、アプリ下部メニューの問い合わせページにてご連絡ください。',
      },
      {
        question: 'カサを紛失してしまいました。',
        answer:
          'お手数ですが、紛失した際は紛失手数料として864円（税込）をいただいております。なお、864円は紛失手数料のためカサを買取する料金ではございません。カサを個人が所有や転売をすることはできませんのでお気をつけください。アイカサ公式アプリのマイページより紛失申請のお手続きをお願いいたします。',
      },
      {
        question: '使った分だけプランと使い放題プランはどう違いますか？',
        answer:
          '使った分だけプランは、24時間以内なら何度貸し借りしても110円のプランです。\n550円が同月内の課金上限になり、翌月までカサを借り続けるとまた翌月1日から24時間110円のご料金が加算されますが、返却されましたら課金はストップいたします。\n\n使い放題プランは、利用するしないに関わらず毎月280円がかかりますか、返却を気にせず使い放題ができる格安プランになります。\nご利用スタイルに合わせてお選びください。',
      },
      {
        question: '使い放題プランを解約したい。',
        answer:
          'マイページの「プラン変更」より、使った分だけプランへ変更をしてください。',
      },
      {
        question: '利用料金が合っているか不安がある。',
        answer:
          '確認させていただきますので、アプリ下部メニューの問い合わせページにてご連絡ください。\n料金システムにつきましては、マイページの「使い方・料金システム」よりご確認ください。',
      },
      {
        question: '請求額の引き落としがされていません。',
        answer:
          '利用時に自動引き落としではなく、毎月1日に引き落としをさせていただいております。',
      },
      {
        question: 'Payからの支払いができません。',
        answer:
          'LINE Payにつきましては、大変お手数ですがLINE Payのお問い合わせよりご確認お願いいたします。',
      },
      {
        question: 'カサのメンテナンスはされていますか？',
        answer:
          '弊社のスタッフが定期的に見回りを行い、カサの取手をシートで拭いて除菌しております。\nまた、カサの本数が偏っている場合には、本数の調整を行っております。',
      },
      {
        question: 'アイカサを退会したい。',
        answer:
          'マイページの「アカウント情報」→「アカウント情報の削除」より、退会をしてください。\nなお、「支払いが完了していない」「レンタル中のカサがある」場合は、退会できませんのでご注意ください。',
      },
      {
        question: '傘を盗まれた・盗難された',
        answer:
          '弊社の方で警察と連携をとらせていただきますので、\n・場所\n・日時（例：1/17,17:00頃）\n・状況（例：傘立てに入れていたら無くなっていた）\nなどの状況をアプリ下部メニューの問い合わせページにてお知らせください。',
      },
    ],
  },
]
