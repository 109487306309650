import { useCallback, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import Icon from '@/components/Icon'

import ModalBackdrop from './ModalBackdrop'

import styles from './Modal.module.css'

type ModalProps = {
  className?: string
  visible?: boolean
  onClose?: () => void
  onCloseButtonClick?: () => void
  mask?: boolean
  maskClosable?: boolean
  children: React.ReactNode
}

function Modal(props: ModalProps) {
  const {
    mask = true,
    maskClosable = true,
    onClose,
    onCloseButtonClick,
    visible = true,
    children,
  } = props

  const [portalContainer, setPortalContainer] = useState<Element | null>(null)
  useEffect(() => {
    setPortalContainer(document.getElementById('app'))
  }, [])

  const onBackdropClick = useCallback(() => {
    if (maskClosable) onClose?.()
  }, [maskClosable, onClose])

  const element = (
    <div className={styles.root}>
      {onCloseButtonClick && (
        <button className={styles.closeButton} onClick={onCloseButtonClick}>
          <Icon name="close" />
        </button>
      )}
      {mask && <ModalBackdrop onClick={onBackdropClick} />}
      <div className={styles.container}>{children}</div>
    </div>
  )

  if (!portalContainer) return null
  if (!visible || !children) return null
  return createPortal(element, portalContainer)
}

export default Modal
