import cx from 'classnames'
import { Helmet } from 'react-helmet-async'

import { useT } from '@/utils/language'

import styles from './Page.module.css'

type PageProps = {
  className?: string
  title?: string
  children?: React.ReactNode
}

function Page(props: PageProps) {
  const T = useT()

  const { className, title = T('ikasa'), children } = props

  return (
    <div className={cx(styles.root, className)}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

export default Page
