import CRC from './crc'
import StandAES from './standAES'

function generateFourRandomValues(unix: number) {
  const randoms = new Uint8Array(4)
  randoms[0] = unix >> 16
  randoms[1] = unix
  randoms[2] = unix >> 24
  randoms[3] = unix >> 8
  return randoms
}

function createCommand(cmd: number) {
  const withoutCrc = new Uint8Array([cmd, 0x03])
  const crc = CRC.make(withoutCrc)
  return new Uint8Array([cmd, 0x03, crc])
}

function generateEncryptedCommand(cmd: number, randoms: Uint8Array) {
  const key = StandAES.generateEncryptKey(randoms)
  const command = createCommand(cmd)
  return StandAES.encrypt(command, key, new Uint8Array(16))
}

function decryptGeneratedCommand(command: Uint8Array, randoms: Uint8Array) {
  const key = StandAES.generateEncryptKey(randoms)
  const decrypted = StandAES.decrypt(command, key, new Uint8Array(16))
  return decrypted
}

function _makeBLEPacket(randoms: Uint8Array, encrypted: Uint8Array) {
  const header = 0xa5
  const packetLength = encrypted.length + 8

  const packetWithoutCrc = new Uint8Array([
    header,
    packetLength,
    ...randoms,
    ...encrypted,
  ])

  const crc = CRC.make(packetWithoutCrc)

  const footer = 0xa5
  const packet = new Uint8Array([...packetWithoutCrc, crc, footer])

  return packet
}

function _readBLEPacket(packet: Uint8Array) {
  const randoms = packet.slice(2, 6)
  const encrypted = packet.slice(6, -2)
  return [randoms, encrypted]
}

export function makeBLEPacket(cmd: number, time: number): Uint8Array {
  const randoms = generateFourRandomValues(time)
  const encrypted = generateEncryptedCommand(cmd, randoms)
  const packet = _makeBLEPacket(randoms, encrypted)
  return packet
}

export function readBLEPacket(packet: Uint8Array) {
  const [randoms, encrypted] = _readBLEPacket(packet)

  // split into 16 byte chunks
  const encryptedList = encrypted.reduce<Uint8Array[]>((acc, cur, idx) => {
    if (idx % 16 === 0) {
      acc.push(new Uint8Array(16))
    }
    acc[acc.length - 1][idx % 16] = cur
    return acc
  }, [])

  const decrypted = encryptedList.reduce<Uint8Array>((acc, cur) => {
    return new Uint8Array([...acc, ...decryptGeneratedCommand(cur, randoms)])
  }, new Uint8Array())
  return decrypted
}

export function encodePacket(packet: Uint8Array) {
  const binaryString = String.fromCharCode.apply(
    null,
    packet as unknown as number[]
  )
  return window.btoa(binaryString)
}

export function decodePacket(packetString: string) {
  const binaryString = window.atob(packetString)
  const packetArray = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    packetArray[i] = binaryString.charCodeAt(i)
  }
  return packetArray
}
