import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import Page from '@/components/Page'
import { useT } from '@/utils/language'

import Step1Image from './images/step-1.png'
import Step2Image from './images/step-2.png'
import Step3Image from './images/step-3.png'

import styles from './WalkthroughPage.module.css'
import Indicator from '@/components/Indicator'

const useStepList = () => {
  const T = useT()
  return useMemo(
    () => [
      {
        image: Step1Image,
        title: T('walkthorughStep1Title'),
        text: T('walkthorughStep1Text'),
      },
      {
        image: Step2Image,
        title: T('walkthorughStep2Title'),
        text: T('walkthorughStep2Text'),
      },
      {
        image: Step3Image,
        title: T('walkthorughStep3Title'),
        text: T('walkthorughStep3Text'),
      },
    ],
    [T]
  )
}

function WalkthroughPage() {
  const T = useT()

  const stepList = useStepList()
  const [stepIndex, setStepIndex] = useState(0)
  const currentStep = stepList[stepIndex]
  const isLastStep = stepIndex === stepList.length - 1

  const navigate = useNavigate()
  const complete = useCallback(() => {
    navigate('/plan', { replace: true })
  }, [navigate])

  if (!currentStep) return null

  const { image, title, text } = currentStep

  return (
    <Page
      className={cx(styles.page, {
        [styles.page0]: stepIndex === 0,
        [styles.page1]: stepIndex === 1,
        [styles.page2]: stepIndex === 2,
      })}
    >
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt="" />
      </div>

      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>

        <Indicator
          className={styles.indicator}
          length={stepList.length}
          current={stepIndex}
          onChange={setStepIndex}
        />

        <button
          className={styles.nextButton}
          onClick={() => {
            if (isLastStep) {
              complete()
              return
            }
            setStepIndex((s) => s + 1)
          }}
        >
          {isLastStep ? T('walkthorughComplete') : T('next')}
        </button>
        {!isLastStep && (
          <button
            className={styles.skipButton}
            onClick={() => {
              complete()
            }}
          >
            {T('skip')}
          </button>
        )}
      </div>
    </Page>
  )
}

export default WalkthroughPage
