import AES from 'crypto-js/aes'
import CryptoJSCore from 'crypto-js/core'
import NoPadding from 'crypto-js/pad-nopadding'

// https://gist.github.com/lettergram/ba6733a854f835bca22b
const u8arrayEncoder = {
  stringify: function (wordArray: CryptoJSCore.lib.WordArray) {
    var words = wordArray.words
    var sigBytes = wordArray.sigBytes
    var u8 = new Uint8Array(sigBytes)
    for (var i = 0; i < sigBytes; i++) {
      var byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
      u8[i] = byte
    }

    return u8
  },
  parse: function (u8arr: Uint8Array) {
    const len = u8arr.length
    const words: number[] = []
    for (var i = 0; i < len; i++) {
      words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8)
    }

    return CryptoJSCore.lib.WordArray.create(words, len)
  },
}

const bleModuleKey = [0x75, 0x6d, 0x62, 0x72, 0x65, 0x6c, 0x6c, 0x61]

function generateEncryptKey(randoms: Uint8Array) {
  const keyBytes = new Uint8Array([...randoms, ...bleModuleKey, ...randoms])
  return keyBytes
}

function encrypt(plainBytes: Uint8Array, key: Uint8Array, iv: Uint8Array) {
  const plainBytesWords = u8arrayEncoder.parse(plainBytes)
  const keyWords = u8arrayEncoder.parse(key)
  const ivWords = u8arrayEncoder.parse(iv)
  const encrypted = AES.encrypt(plainBytesWords, keyWords, {
    iv: ivWords,
    mode: CryptoJSCore.mode.CBC,
    // ONLY decrypt with no padding
    // padding: NoPadding,
  })
  const encryptedString = encrypted.toString()
  const rawString = window.atob(encryptedString)
  const array = Uint8Array.from(rawString.split('').map((c) => c.charCodeAt(0)))
  return array
}

function decrypt(encryptedBytes: Uint8Array, key: Uint8Array, iv: Uint8Array) {
  const rawString = String.fromCharCode.apply(
    null,
    encryptedBytes as unknown as number[]
  )
  const encryptedString = window.btoa(rawString)
  const keyWords = u8arrayEncoder.parse(key)
  const ivWords = u8arrayEncoder.parse(iv)
  const decrypted = AES.decrypt(encryptedString, keyWords, {
    iv: ivWords,
    mode: CryptoJSCore.mode.CBC,
    padding: NoPadding,
  })
  const array = u8arrayEncoder.stringify(decrypted)
  return array
}

const StandAES = { generateEncryptKey, encrypt, decrypt }

export default StandAES
