import { useCallback } from 'react'
import {
  scanCode as spotAppScanCode,
  setClipboardData as spotSetClipboardData,
} from '@chargespot/spjs'

export function useScan() {
  return useCallback(async () => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      const scanResult =
        window.prompt(
          'scan result',
          'https://mobile.i-kasa.com/v2/spot?id=ik_00569'
        ) || ''

      // MOCK no permission
      if (scanResult === '-2') return Promise.reject({ errCode: -2 })
      return scanResult
    }

    return (await spotAppScanCode()).result
  }, [])
}

function navigatorSetClipboardData(text: string) {
  const type = 'text/plain'
  const blob = new Blob([text], { type })
  const data = [new ClipboardItem({ [type]: blob })]
  return navigator.clipboard.write(data)
}

export function useSetClipboardData() {
  return useCallback(async (data: string) => {
    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) {
      return navigatorSetClipboardData(data)
    }

    return await spotSetClipboardData({ data })
  }, [])
}
