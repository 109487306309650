import cx from 'classnames'

import styles from './Icon.module.css'

import { ReactComponent as AccessTimeImage } from './images/access-time.svg'
import { ReactComponent as AccountCircleImage } from './images/account-circle.svg'
import { ReactComponent as ArrowRightImage } from './images/arrow-right.svg'
import { ReactComponent as BeachAccessImage } from './images/beach-access.svg'
import { ReactComponent as CachedImage } from './images/cached.svg'
import { ReactComponent as ChatImage } from './images/chat.svg'
import { ReactComponent as CheckImage } from './images/check.svg'
import { ReactComponent as ChevronRightImage } from './images/chevron-right.svg'
import { ReactComponent as CloseImage } from './images/close.svg'
import { ReactComponent as CopyImage } from './images/copy.svg'
import { ReactComponent as DescriptionImage } from './images/description.svg'
import { ReactComponent as FilterNoneImage } from './images/filter-none.svg'
import { ReactComponent as HistoryImage } from './images/history.svg'
import { ReactComponent as HomeImage } from './images/home.svg'
import { ReactComponent as KasaImage } from './images/kasa.svg'
import { ReactComponent as KeyboardArrowDownImage } from './images/keyboard-arrow-down.svg'
import { ReactComponent as KeyboardArrowUpImage } from './images/keyboard-arrow-up.svg'
import { ReactComponent as LocationOnImage } from './images/location-on.svg'
import { ReactComponent as LocationSearchingImage } from './images/location-searching.svg'
import { ReactComponent as LockImage } from './images/lock.svg'
import { ReactComponent as MonetizationOnImage } from './images/monetization-on.svg'
import { ReactComponent as PersonImage } from './images/person.svg'
import { ReactComponent as SearchImage } from './images/search.svg'
import { ReactComponent as SpannerImage } from './images/spanner.svg'
import { ReactComponent as StyleImage } from './images/style.svg'
import { ReactComponent as TimelapseImage } from './images/timelapse.svg'
import { ReactComponent as TodayImage } from './images/today.svg'
import { ReactComponent as TrendingUpImage } from './images/trending-up.svg'

const IconMap = {
  accessTime: AccessTimeImage,
  accountCircle: AccountCircleImage,
  arrowRight: ArrowRightImage,
  beachAccess: BeachAccessImage,
  cached: CachedImage,
  chat: ChatImage,
  check: CheckImage,
  chevronRight: ChevronRightImage,
  close: CloseImage,
  copy: CopyImage,
  description: DescriptionImage,
  filterNone: FilterNoneImage,
  history: HistoryImage,
  home: HomeImage,
  kasa: KasaImage, // not 24px
  keyboardArrowDown: KeyboardArrowDownImage,
  keyboardArrowUp: KeyboardArrowUpImage,
  locationOn: LocationOnImage,
  locationSearching: LocationSearchingImage,
  lock: LockImage, // not 24px
  monetizationOn: MonetizationOnImage,
  person: PersonImage,
  search: SearchImage,
  spanner: SpannerImage, // not 24px
  style: StyleImage,
  timelapse: TimelapseImage,
  today: TodayImage,
  trendingUp: TrendingUpImage,
}

export type IconName = keyof typeof IconMap

type IconProps = {
  className?: string
  name: IconName
  size?: number
}

function Icon(props: IconProps) {
  const { className, name, size = 24 } = props
  const Icon = IconMap[name]

  return (
    <div
      className={cx(styles.container, className)}
      style={{ width: size, height: size }}
    >
      <Icon className={styles.image} />
    </div>
  )
}

export default Icon
