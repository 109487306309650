import cx from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { UIAction, useHandleUIAction } from '@/utils/uiAction'

import Page from '@/components/Page'

import styles from './UIActionPage.module.css'

function UIActionPage() {
  const [searchParams] = useSearchParams()
  const hanldeUIAction = useHandleUIAction()
  const actionString = searchParams.get('action')

  if (!actionString) return null

  const action = JSON.parse(actionString) as UIAction

  if (action.type !== 'page' || action.page === null) return null
  const { title, subtitle, content, image, button } = action.page

  return (
    <Page title={title}>
      <div className={cx(styles.header, { [styles.headerWithImage]: image })}>
        {image && <img className={styles.image} src={image} alt="" />}
        {title && <div className={styles.title}>{title}</div>}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>

      {content && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      {button && (
        <button
          className={styles.button}
          onClick={() => {
            if (button.action) hanldeUIAction(button.action)
          }}
        >
          {button.text}
        </button>
      )}
    </Page>
  )
}

export default UIActionPage
