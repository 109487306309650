import { useMemo } from 'react'

import { useIkasaId } from '@/utils/setting'
import { useT } from '@/utils/language'

import Page from '@/components/Page'

import styles from './ChatPage.module.css'

function ChatPage() {
  const T = useT()
  const ikasaId = useIkasaId()

  const csPageUrl = useMemo(() => {
    if (!ikasaId) return
    const url = new URL('https://cs.i-kasa.com/boot')
    url.searchParams.set('ikasa_id', ikasaId)
    return url.toString()
  }, [ikasaId])

  return (
    <Page className={styles.page} title={T('chat')}>
      <iframe className={styles.iframe} title={T('chat')} src={csPageUrl} />
    </Page>
  )
}

export default ChatPage
